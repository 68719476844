import axios from "axios";
import store from "@/store";

const state = {
    watches: [],
    status: '',
    loaded: false,
    waiting: false,
};
const actions = {
    resetWatches(){
        this.dispatch('watches/deleteWatches')
    },
    deleteWatches({commit}) {
        commit('SET_WATCHES', [])
        commit('SET_LOADED', false)
    },
    loadWatches(){
        if ( this.loaded ) {
            return;
        }
        let token = store.getters['auth/getToken']
        if(token !== '' && token !== undefined) {
            this.dispatch('watches/loadWatchesWithToken')
        }
    },
    loadWatchesWithToken({commit}) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('WATCHES_ERROR', null)
        commit('SET_WAITING', true)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            crossdomain: true,
        };
        axios.get(store.getters.getAllEnvis.API_PRODUKTE_BEOBACHTUNGEN, config)
            .then(response => {
                commit('SET_WATCHES', response.data)
                commit('SET_LOADED', true)
                commit('SET_WAITING', false)
            } )
            .catch(error => {
                commit('SET_WAITING', false)
                if(error.response.data.message === ''){
                    commit('WATCHES_ERROR', 'error')
                } else {
                    commit('WATCHES_ERROR', error.response.data.message)
                }
            })
    },
    addWatch( {commit}, payload ) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('SET_WAITING', true)
        commit('WATCHES_ERROR', null)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };
        axios.post(store.getters.getAllEnvis.API_PRODUKTE_BEOBACHTUNGEN_ADD.replace( '{id}', payload.trancheId ), 
            {trancheId: payload.trancheId}, config)
            .then(response => {
                commit('SET_WATCHES', response.data)
                commit('SET_WAITING', false)
            } )
            .catch(error => {
                commit('SET_WAITING', false)
                if(error.response.data.message === ''){
                    commit('WATCHES_ERROR', 'error')
                } else {
                    commit('WATCHES_ERROR', error.response.data.message)
                }
            })
    },
    removeWatch( {commit}, payload ) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('WATCHES_ERROR', null)
        commit('SET_WAITING', true)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            }
        };
        axios.delete(store.getters.getAllEnvis.API_PRODUKTE_BEOBACHTUNGEN_DELETE.replace( '{id}', payload.trancheId ), config)
            .then(response => {
                commit('SET_WATCHES', response.data)
                commit('SET_LOADED', false)
                commit('SET_WAITING', false)
                this.resetWatches();
            } )
            .catch(error => {
                commit('SET_WAITING', false)
                if(error.response.data.message === ''){
                    commit('WATCHES_ERROR', 'error')
                } else {
                    commit('WATCHES_ERROR', error.response.data.message)
                }
            })
    },
};
const mutations = {
    SET_WATCHES: (state, responseData) => {
        let tmp = [ responseData.length ];
        for (let i = 0; i < responseData.length; i++) {
            tmp[i] = responseData[i].id;
        }
        state.watches = tmp;
    },
    SET_WAITING: (state, waitingForServerResponse) =>{
        state.waiting = waitingForServerResponse
    },
    SET_LOADED: (state, loaded) =>{
        state.loaded = loaded
    },
    WATCHES_ERROR: (state, errormessage) => {
        state.status = errormessage
    },
};
const getters = {
    getAllWatches: state => state.watches || [],
    watchesStatus: state => state.status,
    isWatched: (state) => (trancheId) => {
        var watches = state.watches || [];
        for( let watchedId of watches ) {
            if ( watchedId == trancheId ) { // same value, won't necessarily be of same type!
                return true;
            }
        }
        return false;
    },
    isWaiting: state => state.waiting,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};


