<template>
  <span>
    <a class="btn btn-default" :class="linkClass" @click="submit">
      <i class="fa fa-file-pdf" aria-hidden="true" style="padding-right: 1em"></i>
      <form
          ref="form"
          :action="href"
          :target="target"
          method="get"
          style="display: inline"
      />
      <slot />
    </a>
  </span>
</template>

<script>
export default {
  name: "DownloadButton",
  props: {
    href: {
      type: String,
      default: '',
    },
    target: {
      type: String,
      default: '_blank',
    },
  },
  computed: {
    linkClass() {
      return {
        'pointer': this.href,      
      }
    },
  },
  methods: {
    submit(event) {
      event.preventDefault()
      if (this.href) this.$refs.form.submit()
    }
  }
}
</script>

<style lang="scss" scoped>
.download-button {
  form {
    display: none;
  }
}
</style>