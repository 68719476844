/**
 * Tests whether a password meets some predefined rules of what
 * might be regarded as strong password. Whenever a password matches
 * one of those rules a certain amount of points will be added to an
 * internal counter. When a special number of points is reached the
 * password is treated a save password. Following is a list of points
 * given for each rule adhered to:
 *
 * <ul>
 * 	<li>rule 1: password length 0 -> 0 points
 * 	<li>rule 2: password length between 1 and 4 -> 3 points
 * 	<li>rule 3: password length between 4 and 8 -> 6 points
 * 	<li>rule 4: password length between 9 and 10 -> 12 points
 * 	<li>rule 5: password length 11 and more -> 18 points
 * 	<li>rule 6: at least one lower case letter -> 1 points
 * 	<li>rule 7: at least one upper case letter -> 5 points
 * 	<li>rule 8: at least one number -> 5 points
 * 	<li>rule 9: at least three numbers -> 5 points
 * 	<li>rule 10: at least one special character -> 5 points
 * 	<li>rule 11: at least two special characters -> 5 points
 * 	<li>rule 12: upper and lower case letters -> 2 points
 * 	<li>rule 13: letters and numbers -> 2 points
 * 	<li>rule 14: upper case, lower case letters, numbers and special characters -> 2 points
 * </ul>
 *
 * @param passwd  the password to check
 * @return PasswordAttrib to fill out the visual help
 */
function testPassword(passwd) {
    var description = [];
    var result = null;
    var intScore = 0;

    description[0] = {bar_fill_percant:30, variant:'danger', pwd_strength_res:'sehr schwach', sufficient:false};
    description[1] = {bar_fill_percant:60, variant:'warning', pwd_strength_res:'schwach', sufficient:false};
    description[2] = {bar_fill_percant:90, variant:'info', pwd_strength_res:'gut', sufficient:true};
    description[3] = {bar_fill_percant:95, variant:'success', pwd_strength_res:'stark', sufficient:true};
    description[4] = {bar_fill_percant:100, variant:'success', pwd_strength_res:'sehr stark', sufficient:true};
    description[5] = {bar_fill_percant:0, variant:'danger', pwd_strength_res:'sehr schwach', sufficient:false};

    if ( !passwd ) {
        return description[5];
    }
    
    // PASSWORD LENGTH
    if (!passwd.length || passwd.length === 0) {
        intScore = -1;
    } else if (passwd.length > 0 && passwd.length <= 4) {
        intScore += 3;
    } else if (passwd.length > 4 && passwd.length <= 8) {
        intScore += 6;
    } else if (passwd.length > 8 && passwd.length <= 10) {
        intScore += 12;
    } else if (passwd.length > 10) {
        intScore += 18;
    }

    // LETTERS
    if (passwd.match(/[a-z]/)) {
        intScore += 1;
    }
    if (passwd.match(/[A-Z]/)) {
        intScore += 5;
    }

    // NUMBERS
    if (passwd.match(/\d+/)) {
        intScore += 5;
    }
    if (passwd.match(/(.*\d.*\d)/)) {
        intScore += 5;
    }

    // SPECIAL CHAR
    if (passwd.match(/\W/)) {
        intScore += 5;
    }
    if (passwd.match(/(.*\W.*\W)/)) {
        intScore += 5;
    }

    // COMBOS
    if (passwd.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) {
        intScore += 2;
    }
    if (passwd.match(/([a-zA-Z])/) && passwd.match(/([0-9])/)) {
        intScore += 2;
    }
    if (passwd.match(/([a-zA-Z0-9].*\W)|(\W.*[a-zA-Z0-9])/)) {
        intScore += 2;
    }

    //CALCULATE FINAL SCORE
    if (intScore === -1) {
        result = description[5];
    } else if (intScore > -1 && intScore <= 15) {
        result = description[0];
    } else if (intScore > 15 && intScore <= 24) {
        result = description[1];
    } else if (intScore > 24 && intScore <= 34) {
        result = description[2];
    } else if (intScore > 34 && intScore <= 45) {
        result = description[3];
    } else {
        result = description[4];
    }

    return result;
}
export default testPassword;