<template>
  <div>
    <section class="container error-margin">
      <div class="text-center">
        <h1 class="portalHead">{{ title }}</h1>
      </div>
    </section>
    <section class="container center error-card">
      <div class="card">
        <div class="news-min-height card-body">
          <b-container>
            <b-row>
              {{ message }}
            </b-row>
          </b-container>
        </div>
      </div>
    </section>

<!--    TODO: remove-->
<!--    <section class="container error-margin">-->
<!--      <div class="text-center">-->
<!--        <h1 v-if="errorType == '404'">Seite nicht gefunden</h1>-->
<!--        <h1 v-if="errorType == 'ClientNotFound'" class="portalHead">Portal bzw. Plattform existiert nicht</h1>-->
<!--      </div>-->
<!--    </section>-->
<!--    <section class="container center error-card">-->
<!--      <div class="card">-->
<!--        <div class="news-min-height card-body">-->
<!--          <b-container>-->
<!--            <b-row>-->
<!--              <b-col v-if="errorType == '404'">-->
<!--                Es tut uns leid, aber die Seite, die Sie gesucht haben, existiert nicht. Bitte überprüfen Sie die Adresse und versuchen Sie es noch einmal.-->
<!--              </b-col>-->
<!--              <b-col v-if="errorType == 'ClientNotFound'">-->
<!--                Es tut uns leid, aber das ausgewählte Portal bzw. die ausgewählte Plattform ist nicht verfügbar. Bitte überprüfen Sie die Adresse und versuchen Sie es noch einmal.-->
<!--              </b-col>-->
<!--            </b-row>-->
<!--          </b-container>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
  </div>
</template>

<script>

// TODO: remove done by vue transition within App.vue
// import $ from "jquery";

export default {
  name: 'ErrorPage',
  props: {
    errorType: {
      type: String,
      default: '404'
    }
  },
  metaInfo() {
    return {
      htmlAttrs: {
        lang: 'de-DE'
      },
      meta: [
        { name: 'robots', content: "noindex, nofollow", vmid: 'robots' },
        { name: 'googlebot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'googlebot' },
        { name: 'bingbot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'bingbot' },
      ],
    }
  },
  data: () => ({
    errors: {
      '404': {
        title: 'Seite nicht gefunden',
        message: 'Es tut uns leid, aber die Seite, die Sie gesucht haben, existiert nicht. Bitte überprüfen Sie die Adresse und versuchen Sie es noch einmal.',
      },
      'client-not-found': {
        title: 'Portal bzw. Plattform existiert nicht',
        message: 'Es tut uns leid, aber das ausgewählte Portal bzw. die ausgewählte Plattform ist nicht verfügbar. Bitte überprüfen Sie die Adresse und versuchen Sie es noch einmal.',
      },
    },
  }),
  computed: {
    title() {
      return this.getError(this.errorType)?.title
    },
    message() {
      return this.getError(this.errorType)?.message
    },
  },
  mounted() {
    // TODO: remove done by vue transition within App.vue
    // this.$nextTick(function () {
    //   $('#pageLoader').css('display', 'none');
    // })
  },
  methods: {
    getError(errorType) {
      return this.errors[errorType] || this.errors['404']
    }
  },
}
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap-grid.scss";

.portalHead {
  margin-top: 3em;
}

.hero {
  z-index: -1;
}

.error-margin {
  margin-top: 3em;
  margin-bottom: 18em;
}

.error-card {
  margin-top: -14em;
}

.card {
  margin-bottom: 5.5em;
}

@include media-breakpoint-down(lg) {
  .error-card {
    margin-top: -14em;
  }
}
</style>
