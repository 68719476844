import axios from "axios";
import store from "@/store";
import Vue from "vue";

const state = {
    notedata: [],
    status: '',
    waiting: false,
    success: null,
};
const actions = {
    resetNoteData(){
        this.dispatch('deleteNoteData')
    },
    setNoteWaiting({commit}) {
        commit('SET_WAITING',true);
    },
    loadNote({commit},payload) {
        return new Promise((resolve, reject) => {
            let token = store.getters['auth/getToken']
            if (token === '' || token === undefined) {
                throw new Error('Missing Token!')
            }
            commit('NOTE_ERROR', null)
            commit('SET_WAITING', true)

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                },
                crossdomain: true,
            };

            var url;

            if (payload.objectType === 'AUFTRAG') {
                url = store.getters.getAllEnvis.API_USER_AUFTRAGNOTE.replace('{auftragId}', payload.objectId)
            } else if (payload.objectType === 'VERMITTLUNG') {
                url = store.getters.getAllEnvis.API_USER_VERMITTLUNGNOTE.replace('{vermittlungId}', payload.objectId)
            }

            axios.get(url, config)
                .then(response => {
                    commit('SET_NOTEDATA', response.data)
                    commit('SET_WAITING', false)
                    resolve( response )
                }, error => {
                    reject(error);
                })
                .catch(error => {
                    commit('SET_WAITING', false)
                    if (error.response.data.message === '') {
                        console.log(error);
                        commit('NOTE_ERROR', 'error')
                    } else if (error.response.status === 404) {
                        commit('NOTE_ERROR', null)
                    } else {
                        console.log(error);
                        commit('NOTE_ERROR', error.response.data.message)
                    }
                })
        })

    },
    saveNoteData( {commit}, payload ) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        const reqParams = {
            title:            payload.title,
            content:          payload.content,
        };
        var config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            crossdomain: true,
        };

        commit('NOTE_ERROR', null)

        var url;
        if(payload.objectType === 'AUFTRAG') {
            url = store.getters.getAllEnvis.API_USER_AUFTRAGNOTE.replace('{auftragId}',payload.objectId)
        } else if(payload.objectType === 'VERMITTLUNG') {
            url = store.getters.getAllEnvis.API_USER_VERMITTLUNGNOTE.replace('{vermittlungId}',payload.objectId)
        }

        if(payload.hasNote === true) {
            return axios.post(url,reqParams,config)
                .then(response => {
                    commit('SET_NOTEDATA', response.data)
                    commit('SET_WAITING', false)
                    commit('SET_SUCCESS', true)
                })
                .catch(error => {
                    console.log( error );
                    commit('SET_WAITING', false)
                    commit('SET_SUCCESS', false)
                    if(error.response.data.message === ''){
                        commit('NOTE_ERROR', 'error')
                    } else {
                        commit('NOTE_ERROR', error.response.data.message)
                    }
                })

        } else {

            return axios.put(url,reqParams,config)
                .then(response => {
                    commit('SET_NOTEDATA', response.data)
                    commit('SET_WAITING', false)
                    commit('SET_SUCCESS', true)
                })
                .catch(error => {
                    console.log( error );
                    commit('SET_WAITING', false)
                    commit('SET_SUCCESS', false)
                    if(error.response.data.message === ''){
                        commit('NOTE_ERROR', 'error')
                    } else {
                        commit('NOTE_ERROR', error.response.data.message)
                    }
                })
        }
    },
    deleteNoteData( {commit}, payload ) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)

        var config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            crossdomain: true,
        };

        commit('NOTE_ERROR', null)
        var url;
        if(payload.objectType === 'AUFTRAG') {
            url = store.getters.getAllEnvis.API_USER_AUFTRAGNOTE.replace('{auftragId}',payload.objectId)
        } else if(payload.objectType === 'VERMITTLUNG') {
            url = store.getters.getAllEnvis.API_USER_VERMITTLUNGNOTE.replace('{vermittlungId}',payload.objectId)
        }

        return axios.delete(url,config)
            .then(response => {
                commit('SET_NOTEDATA', response.data)
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
            })
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                if(error.response.data.message === ''){
                    commit('NOTE_ERROR', 'error')
                } else {
                    commit('NOTE_ERROR', error.response.data.message)
                }
            })
    },
};
const mutations = {
    SET_NOTEDATA: (state, responseData) => {
        Vue.set( state, "notedata", responseData );
    },
    SET_WAITING: (state, waitingForServerResponse) =>{
        state.waiting = waitingForServerResponse
    },
    NOTE_ERROR: (state, errormessage) => {
        state.status = errormessage
    },
    SET_SUCCESS: (state, success) =>{
        state.success = success
    },
};
const getters = {
    getNoteData: state => state.notedata,
    noteStatus: state => state.status,
    isNoteWaiting: state => state.waiting,
    isNoteLoaded: state => state.notedata !== undefined,
    isSuccess: state => state.success,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};


