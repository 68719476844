const state = {
    unframedPages: ["KaeufermailEmail"],
};
const getters = {
    isFramedPage: (state) => (route) => {
        var pagesWithoutDecoration = state.unframedPages || [];
        for (let page of pagesWithoutDecoration) {
            if (route && route.includes(page)) {
                return false;
            }
            return true;
        }
    },
};

export default {
    state,
    getters,
};