<template>
  <div class="footer mzm-footer d-print-none w-100" v-if="framedPage">
    <div style="width:100%; margin-left: auto; margin-right: auto;">
      <iframe v-if="isMeinZweitmarkt"
              id="iFrameResizer0"
              v-resize="{ log: false }"
              src="https://www.zweitmarkt.de/footer_fdbmzm.html"
              style="width: 100%; margin-bottom: -7px; border:none;"
              title="Footer"></iframe>
      <iframe v-if="isMeinHandel"
              id="iFrameResizer1"
              v-resize="{ log: false }"
              src="https://footer.deutsche-zweitmarkt.de/"
              style="width: 100%; margin-bottom: -7px; border:none;"
              title="Footer"></iframe>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import iframeResize from 'iframe-resizer/js/iframeResizer';

export default {
  name: "ExternalSourceFooter",
  computed: {
    framedPage() {
      return this.$store.getters.isFramedPage( this.$route.path );
    },
    client() {
      return this.$route?.params?.client
    },
    isMeinZweitmarkt() {
      if (this.client === "meinzweitmarkt") {
        return true;
      }
      return false;
    },
    isMeinHandel() {
      if (this.client === "meinhandel") {
        return true;
      }
      return false;
    },
  }
}

Vue.directive('resize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el))
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  }
})
</script>

<style scoped>

</style>