<template>
  <div>
    <section class="container error-margin">
      <div class="text-center">
        <h1 class="portalHead">Automatische Weiterleitung</h1>
      </div>
    </section>
    <section class="container center error-card">
      <div class="card">
        <div class="news-min-height card-body">
          <b-container>

            <div style="margin-left: -15px; margin-right: -15px; display: block; text-align: center">
              Klicken Sie bitte&#160;<router-link :to="redirectUrl" v-if="redirectUrl">hier</router-link>
              <span v-else>...</span>&#160;falls Ihr Browser Sie nicht automatisch weiterleitet.
            </div>

          </b-container>
        </div>
      </div>
    </section>
    
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "FondsdetailsRedirect",
  created() {
    this.decodeTrancheId();
  },
  data: () => ({
    metaTitle: '..:: Zweitmarkt ::..',
    metaDescription: 'Fondsportrait mit Kursen im Zeitverlauf, Beteiligungsdaten und Statistiken. Jetzt informieren!',
    redirectUrl: null,
    decodedTrancheId: null,
  }),
  computed: {
    client() {
      return this.$route?.params?.client
    },
  },
  methods: {
    decodeTrancheId() {
      /* Decode wi/fondsdetails?tid=ZVU7vkhqCOaucFO9-4O18w to obtain true tranche ID */

      let encodedTrancheId;
      if(this.$route.query.tid !== undefined) {
        encodedTrancheId = this.$route.query.tid
      } else if(this.$route.params.id !== undefined) {
        encodedTrancheId = this.$route.params.id;
      } else {
        encodedTrancheId = null;
      }

      axios.get( this.$store.getters.getAllEnvis.API_PRODUKTE_ZWM_DECODE_TRANCHE_ID + '?tid=' + encodedTrancheId )
        .then(response => {
          const trancheId = response.data;

          if (window.location.href.includes('/wi/fondsdetails/')) {
            const path = this.$route.path;
            this.redirectUrl = path.replace('/wi', '');
          } else if (window.location.href.includes('/wi/fondsdetails?')) {
            const path = this.$route.path;
            this.redirectUrl = path.replace('/wi', '').replace('?tid=', '/')  + '/' + trancheId;
          } else {
            this.redirectUrl = 'fondsdetails/' + trancheId;
          }
          // this works as a 301 for google.
          // https://stackoverflow.com/questions/13846738/are-301-redirects-possible-using-javascript-or-jquery
          window.location.href = this.redirectUrl;
        } )
          .catch(error => {
          console.log(error);
          this.redirectUrl = 'fondsliste';
          // window.location.href = this.redirectUrl;
        })

    },
  },
  watch: {
    $route: 'decodeTrancheId'
  },
}
</script>

<style scoped>

</style>