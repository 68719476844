import VueCookie from 'vue-cookie'

const state = {
  environmental: [],
  subsite: undefined,
  mobileBreakpoint: "980"
};

const actions = {
  loadEnv({commit}) {

    let envi;
    if (VueCookie.get('mzm2-env')) {
      envi = decodeURIComponent(VueCookie.get('mzm2-env'));
      envi = window.atob( envi );
      // "{\"stage\":\"local\"}"
      envi = JSON.parse(envi).stage;
    }

    const environment = require('../../environmentoptions/environments.js').environment(envi, true)
    commit('SET_ENV', environment)
  },
  setSubsite({commit},payload) {
    commit( 'SET_SUBSITE', payload.subsite );
  }
}

const mutations = {
  SET_ENV: (state, environmental) => {
    state.environmental = environmental
  },
  SET_SUBSITE: (state, subsite) => {
    state.subsite = subsite
  }
}

const getters = {
  getAllEnvis: state => state.environmental,
  getStage: state => state.environmental.API_STAGE,
  getMobileBreakpoint: state => state.mobileBreakpoint,
  getSubsite(state) {
    if (!state.subsite) {
      let s = window.location.pathname?.split('/')?.[1]
      console.log('environments/getSubsite: Undefined state.subsite, returning ' + s);
      return s;
    }
    return state.subsite;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
};
