<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText :titletext="'Mein Zweitmarkt'" :subtext="'Ihr Handelsplatz für geschlossene Fondsbeteiligungen'"></HeadText>
        </div>
        <div v-if="sessionLoading" class="pt-4">
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Ihre Sitzung wird aufgebaut</strong>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import $ from "jquery";

export default {
  name: "RemoteLogin",
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
        { name: 'robots', content: "noindex, nofollow", vmid: 'robots' },
        { name: 'googlebot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'googlebot' },
        { name: 'bingbot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'bingbot' },
      ],
    }
  },
  components: {Hero, HeadText},
  computed: {
    subsiteDisplayName() {
      return this.isMeinHandel ? 'MeinHandel' : 'MeinZweitmarkt'
    },
    isMeinHandel() {
      return this.$store.getters["getSubsite"] === 'meinhandel';
    },
  },
  data: () => ({
    sessionLoading: true,
  }),
  mounted: function closeNav () {
    $('#nav-collapse').css('display', 'none');
  },
  created() {
    let accessToken = this.getAccessToken()
    if ( accessToken === undefined || accessToken === null || accessToken === '' ) {
      accessToken = this.getToken();
      console.warn( "Deprecated parameter 'token' accepted" );
    }
    const targetPage = this.getTargetPage();
    if ( accessToken !== undefined && accessToken !== null && accessToken !== '' ) {
      const formData = {
        token: accessToken,
        targetPage: this.getTargetPage(),
      }
      this.$store.dispatch('auth/acceptRemoteToken', formData);
    }
    else if ( targetPage !== undefined && targetPage !== null && targetPage !== '' ) {
      const payload = {
        name: targetPage,
      };
      this.$store.dispatch('auth/backToOrigin', payload )
    }
    else {
      this.$store.dispatch('auth/backToMain')
    }
    this.sessionLoading = false;
   
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    getToken() {
      return this.$route.query.token;
    },
    getAccessToken() {
      return this.$route.query.access_token;
    },
    getTargetPage() {
      return this.$route.query.targetPage;  
    }
  }
}
</script>

<style scoped>

</style>