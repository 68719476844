/**
 * Tests whether a password contains illegal characters. Returns false if there are illegal characters.
 * 
 * Illegal characters: \ % | ^
 */
function testForIllegalCharacters(passwd) {
    
    if ( !passwd ) {
        return true;
    }
    
    var illegals = ["\\","%","|","^"];
    for ( var ix in illegals ) {
        var illegalCharacter = illegals[ix];
        if ( passwd.includes( illegalCharacter ) ) {
            return false;
        }
    }
    
    return true;
}

export default testForIllegalCharacters;