import axios from "axios";
import store from "@/store";

const state = {
};
const actions = {
    redirectToAuftrag({commit},payload) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('AUFTRAGSLIST_ERROR', null)
        var config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            crossdomain: true,
        };
        axios.get(store.getters.getAllEnvis.API_AUFTRAGID.replace("{auftragNr}",payload.auftragNr), config)
            .then(response => {
                window.location.href = '/' + payload.client + '/auftragsdetails/' + response.data;
            } )
            .catch(error => {
                console.log(error);
                commit('AUFTRAGSLIST_ERROR', 'Unbekannter Auftrag')
            })
    },
};
const mutations = {
};
const getters = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};


