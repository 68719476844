<template>
  <div class="head-text pt-5">
    <div class="row">
      <h1 class="h1-page-title col-sm-12" :class="{ 'h1-mobile': mobileViewport }">{{titletext}}</h1>
    </div>
    <div class="row">
      <h2 class="h2-page-title col-sm-12" :class="{ 'h2-mobile': mobileViewport }">{{subtext}}</h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadText",
  props: {
    titletext: {
      type: String,
      default: 'Zweitmarkt',
    },
    subtext: {
      type: String,
      default: 'Entdecken Sie die Welt unserer Fonds.',
    }
  },
  computed: {    
    mobileViewport() {
      return window.innerWidth < this.$store.getters.getMobileBreakpoint;
    }
  }
}
</script>

<style scoped>

</style>