import store from '@/store'

const isHostAllowed = function(host) {
  let allowlist = store.getters['clientConfig/config']('hosts') || ''

  if (typeof allowlist === 'string') allowlist = [allowlist]

  host = host.replace(/:.*/, '')

  return allowlist?.some(pattern => {
    pattern = pattern
      .replace(/\./g, '\\.') //escape all `.`
      .replace(/\*/g, '.+?') //replace wildcard `*`

    return !!host.match(RegExp(`^${pattern}$`))
  })
}

const getRouterPathPart = (to, position = 0) => to?.fullPath?.split('/')[position]?.toLowerCase()

const clients = async (to, from, next) => {
  // read client from store
  let client = store.getters['getSubsite']

  // get client from path
  const firstPathPart = getRouterPathPart(to, 1)

  if (firstPathPart === 'error') return next()

  // check if client from store exist, if not replace with path client
  if (!client) client = firstPathPart

  const isConfigExist = await store.dispatch('clientConfig/getConfig', client)

   if (isConfigExist && isHostAllowed(location.host)) {
    if (firstPathPart === client) {
      next()
    } else {
      next({path: `/${client}${to.fullPath}`})
    }
  } else {
    next({path: '/error/client-not-found'})
  }
}

export default clients
