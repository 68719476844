import axios from "axios";
import store from '../index';

const data = {
    test: "test",
    downloadPayload: null,
    downloadTabPayload: null,
};

const actions = {
    getComplianceDownloads({commit}, tid) {
        if(!tid) { console.log(commit) }

        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }

        var subsite = null;

        if(store.getters['getSubsite']) {
            subsite = store.getters['getSubsite'];
        } else {
            subsite = window.location.pathname?.split('/')?.[1];
        }

        var config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: {
                subsite: subsite,
            }
        }

        return axios.get( store.getters.getAllEnvis.API_VERTRAULICHKEITSZUSTIMMUNG_DOWNLOADS.replace("{id}", tid), config)
    },

    setDownloadPayload({commit}, payload) {
        commit('DOWNLOAD_PAYLOAD', {
            filename: payload.filename,
            href: payload.href,
            subsite: payload.subsite,
        });
    },

    setDownloadTab({commit}, payload) {
        commit('DOWNLOAD_TAB_PAYLOAD', {
            selectedTab: payload.selectedTab,
        });
    },

    deleteDownloadPayload({commit}) {
        commit('DOWNLOAD_PAYLOAD', null);
    },

};

const getters = {
    test : state => state.test,
    downloadPayload: state => state.downloadPayload,
    downloadTabPayload: state => state.downloadTabPayload,
};

const mutations = {
    DOWNLOAD_PAYLOAD: (state, data) => {
        state.downloadPayload = data;
    },
    DOWNLOAD_TAB_PAYLOAD: (state, data) => {
        state.downloadTabPayload = data;
    },
};

export default {
    namespaced: true,
    state: data, // state => data weil sucht nach dem objekt state
    actions,
    getters,
    mutations,
};