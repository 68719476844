import axios from "axios";
import store from "../../store";

const state = {
    status: null,
    validationErrors: [],
    waiting: false,
    success: false,
    registeredData: {}
};

function extractLogin( registeredData ) {
    for( let i in registeredData ) {
        let item = registeredData[i];
        if ( 'login' === item.field.fieldName ) {
            return item.value;
        }
    }
    return '';
}

const actions = {
    resetState({commit}) {
        commit('REGISTRATION_ERROR', null)
        commit('SET_WAITING', false)
        commit('SET_SUCCESS', false)
        commit('REGISTRATION_VALIDATION_ERRORS',[])
        commit('REGISTERED_DATA', null )
    },
    postRegistrationData({commit},payload) {
        commit('REGISTRATION_ERROR', null)
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        commit('REGISTRATION_VALIDATION_ERRORS',[])
        commit('REGISTERED_DATA', null )

        let query = '?email='+payload.email+'&legalEntityType='+payload.legalEntityType;
        if ( payload.anrede ) {
            query = query+'&anrede='+payload.anrede;
        }
        if ( payload.titel ) {
            query = query+'&titel='+payload.titel;
        }
        if ( payload.vorname ) {
            query = query+'&vorname='+payload.vorname;
        }
        if ( payload.nachname ) {
            query = query+'&nachname='+payload.nachname;
        }
        if ( payload.firma ) {
            query = query+'&firma='+payload.firma;
        }
        if ( payload.newsletterSubscription ) {
            query = query+'&newsletterSubscription='+payload.newsletterSubscription;
        }
        query = query + '&password=not-logged-here';
        
        console.log( 'Submitting registration request with: ' + query );

        var config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            crossdomain: true,
        }

        var subsite = null;

        if(store.getters['getSubsite']) {
            subsite = store.getters['getSubsite'];
        } else {
            subsite = window.location.pathname?.split('/')?.[1];
        }

        const requestBody = JSON.stringify( { email: payload.email, password: payload.password, 
            legalEntityType: payload.legalEntityType, anrede: payload.anrede, titel: payload.titel, vorname: payload.vorname,
            nachname: payload.nachname, firma: payload.firma, newsletterSubscription: payload.newsletterSubscription } );
        let url = store.getters.getAllEnvis.API_REGISTRATION_START.replace("{subsite}", subsite );
        return axios.post( url, requestBody, config )
            .then(response => {
                console.log( response )
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
            } )
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                if ( error.response.status === 400 ) {
                    commit('REGISTRATION_ERROR', 'Ihre Registrierung wurde abgelehnt')
                    commit('REGISTRATION_VALIDATION_ERRORS',error.response.data.errors.content)
                }
                else {
                    commit('REGISTRATION_ERROR', 'Fehler beim Speichern')
                }
                
            })
        
    },
    postConfirmRegistration({commit},payload) {
        commit('REGISTRATION_ERROR', null)
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        commit('REGISTRATION_VALIDATION_ERRORS',[])
        commit('REGISTERED_DATA', null )

        let query = '?token='+payload.token;
        var config = {
            crossdomain: true,
        };

        var subsite = null;

        if(store.getters['getSubsite']) {
            subsite = store.getters['getSubsite'];
        } else {
            subsite = window.location.pathname?.split('/')?.[1];
        }

        let url = store.getters.getAllEnvis.API_REGISTRATION_CONFIRM.replace("{subsite}", subsite) + query;
        return axios.post(url, config)
            .then(response => {
                console.log( response )
                commit('REGISTERED_DATA', extractLogin( response.data.payload.registeredData ) )
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
            } )
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                
                if ( error.response.status === 400 ) {
                    var completeFailure = true;
                    for (let errorCode of error.response.data.errors.content) {
                        if ( 'E5001' === errorCode ) {
                            completeFailure = false;    
                        }
                    }
                    if ( completeFailure ) {
                        commit('REGISTRATION_ERROR', 'Ihre Registrierung konnte nicht übernommen werden')
                    }
                    else {
                        commit('REGISTRATION_ERROR', 'Ihre Registrierung konnte nicht vollständig übernommen werden.')
                    }
                    commit('REGISTRATION_VALIDATION_ERRORS',error.response.data.errors.content)
                }
                else {
                    commit('REGISTRATION_ERROR', 'Fehler beim Speichern')
                }

            })
    },
    postConfirmNewsletterRegistration({commit},payload) {
        commit('REGISTRATION_ERROR', null)
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        commit('REGISTRATION_VALIDATION_ERRORS',[])
        commit('REGISTERED_DATA', null )

        let query = '?token='+payload.token;
        var config = {
            crossdomain: true,
        };

        var subsite = null;

        if(store.getters['getSubsite']) {
            subsite = store.getters['getSubsite'];
        } else {
            subsite = window.location.pathname?.split('/')?.[1];
        }

        let url = store.getters.getAllEnvis.API_REGISTRATION_NEWSLETTERCONFIRM.replace("{subsite}", subsite) + query;
        return axios.post(url, config)
            .then(response => {
                console.log( response )
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
            } )
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                if ( error.response.status === 400 ) {
                    commit('REGISTRATION_ERROR', 'Ihre Bestätigung konnte nicht übernommen werden')
                    commit('REGISTRATION_VALIDATION_ERRORS',error.response.data.errors.content)
                }
                else {
                    commit('REGISTRATION_ERROR', 'Fehler beim Speichern')
                }

            })
    },
    postConfirmLoginCreation({commit},payload) {
        commit('REGISTRATION_ERROR', null)
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        commit('REGISTRATION_VALIDATION_ERRORS',[])
        commit('REGISTERED_DATA', null )

        let query = '?token='+payload.token+'&password='+payload.password;
        if ( payload.newsletterSubscription ) {
            query = query+'&newsletterSubscription='+payload.newsletterSubscription;
        }
        var config = {
            crossdomain: true,
        };

        var subsite = null;

        if(store.getters['getSubsite']) {
            subsite = store.getters['getSubsite'];
        } else {
            subsite = window.location.pathname?.split('/')?.[1];
        }

        let url = store.getters.getAllEnvis.API_REGISTRATION_LOGINCONFIRM.replace("{subsite}", subsite) + query;
        return axios.post(url, config)
            .then(response => {
                console.log( response )
                commit('REGISTERED_DATA', extractLogin( response.data.payload.registeredData ) )
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
            } )
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                if ( error.response.status === 400 ) {
                    commit('REGISTRATION_ERROR', 'Ihre Bestätigung konnte nicht übernommen werden')
                    commit('REGISTRATION_VALIDATION_ERRORS',error.response.data.errors.content)
                }
                else {
                    commit('REGISTRATION_ERROR', 'Fehler beim Speichern')
                }

            })
    },

};
const mutations = {
    SET_WAITING: (state, waitingForServerResponse) =>{
        state.waiting = waitingForServerResponse
    },
    SET_SUCCESS: (state, success) =>{
        state.success = success
    },
    REGISTRATION_ERROR: (state, errormessage) => {
        state.status = errormessage
    },
    REGISTRATION_VALIDATION_ERRORS: (state, validationErrors) => {
        state.validationErrors = validationErrors
    },
    REGISTERED_DATA: (state, registeredData) => {
        state.registeredData = registeredData
    },
};
const getters = {
    getRegistrationErrorStatus: state => state.status,
    getRegistrationValidationErrors: state => state.validationErrors,
    getRegisteredData: state => state.registeredData,
    isRegistrationSuccess: state => state.success,
    isRegistrationWaiting: state => state.waiting,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};


