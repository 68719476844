<template>
  <div class="text-left">
    <div class="py-3">
      <h1>Styles Demo Component</h1>
      <p>This component demonstrates the look and feel of various elements - h1, p, links, buttons, tables etc.</p>
    </div>
    <div class="py-3">
      <div>
        <h1 class="d-inline pr-3">H1</h1><h2 class="d-inline pr-3">H2</h2><h3 class="d-inline pr-3">H3</h3><h4 class="d-inline pr-3">H4</h4>
      </div>
      <p>
        <a href="https://zweitmarkt.de" target="_blank">Lorem ipsum</a> dolor sit amet, consectetur adipiscing elit, sed 
        do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation 
        ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate 
        velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in 
        culpa qui officia deserunt mollit anim id est laborum.
      </p>
    </div> 
    <div class="row py-3">
      <div class="col-sm-3">
        <a href="https://zweitmarkt.de" target="_blank" class="btn btn-default">Mehr erfahren</a>
      </div>
      <div class="col-sm-3">
        <a href="https://zweitmarkt.de" target="_blank" class="btn btn-default btn-bordered">Kontakt</a>
      </div>
      <div class="col-sm-3">
        <DownloadButton :href="kauefermailPdf">Käufermail</DownloadButton>
      </div>
      <div class="col-sm-3">
        <a href="https://zweitmarkt.de" target="_blank" class="btn btn-default disabled" disabled="disabled">Nicht verfügbar</a>
      </div>
    </div>
    <div class="row py-3">
      <div class="col-sm-12">
        <table class="table table-bordered table-striped">
          <thead class="thead-dark">
            <tr>
              <th>Spalte A</th>
              <th>Spalte B</th>
              <th>Spalte C</th>
              <th>Spalte D</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Immobilien</td>
              <td>Schiffe</td>
              <td>Raumschiffe</td>
              <td>Freizeitparks</td>  
            </tr>
            <tr>
              <td>EUR 31.123,00</td>
              <td>USD 423.999,99</td>
              <td>GBP -34,45</td>
              <td>CHF 999.999.999,99</td>
            </tr>
            <tr>
              <td>Lorem ipsum</td>
              <td>dolor sit amet</td>
              <td>consectetur adipiscing elit</td>
              <td>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</td>
            </tr>
            <tr>
              <td>Lorem ipsum</td>
              <td>dolor sit amet</td>
              <td>consectetur adipiscing elit</td>
              <td>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</td>
            </tr>
            <tr>
              <td>Lorem ipsum</td>
              <td>dolor sit amet</td>
              <td>consectetur adipiscing elit</td>
              <td>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</td>
            </tr>
            <tr>
              <td>Lorem ipsum</td>
              <td>dolor sit amet</td>
              <td>consectetur adipiscing elit</td>
              <td>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</td>
            </tr>
            <tr>
              <td>Lorem ipsum</td>
              <td>dolor sit amet</td>
              <td>consectetur adipiscing elit</td>
              <td>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</td>
            </tr>
            <tr>
              <td>Lorem ipsum</td>
              <td>dolor sit amet</td>
              <td>consectetur adipiscing elit</td>
              <td>sed do eiusmod tempor incididunt ut labore et dolore magna aliqua</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadButton from "@/components/buttons/DownloadButton";
export default {
  name: "StylesDemo",
  components: {DownloadButton},
  computed: {
    kauefermailPdf() { return "https://www.zweitmarkt.de/kaeufermail.pdf"; }  
  },
}
</script>

<style scoped>

</style>