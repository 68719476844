<template>
  <a v-if="isMeinZweitmarkt" href="https://www.zweitmarkt.de/datenschutz.html" target="_blank">Datenschutzbestimmungen</a>

  <a v-else-if="isMeinHandel" href="https://www.deutsche-zweitmarkt.de/Datenschutz.9.0.html" target="_blank">Datenschutzbestimmungen</a>
</template>

<script>
export default {
  name: "FdbDataProtectionRulesLink",
  computed: {
    client() {
      return this.$route?.params?.client
    },
    isMeinZweitmarkt() {
      if (this.client === "meinzweitmarkt") {
        return true;
      }
      return false;
    },
    isMeinHandel() {
      if (this.client === "meinhandel") {
        return true;
      }
      return false;
    },
  }
}
</script>

<style>

</style>