import Vuex from 'vuex';
import Vue from 'vue';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth';
import user from './modules/user';
import compliance from './modules/compliance';
import complianceDownloads from './modules/complianceDownloads';
import files from './modules/files'
import watches from "./modules/watches";
import framedPage from "./modules/framedPage";
import kaeufermail from "./modules/kaeufermail";
import auftragsliste from "./modules/auftragsliste";
import vermittlungsliste from "./modules/vermittlungsliste";
import auftragswizard from "./modules/auftragswizard";
import notes from "./modules/notes";
import registration from "./modules/registration";
import environment from './modules/environment';
import clientConfig from './modules/clientConfig';

Vue.use(Vuex);

const dataState = createPersistedState({
    paths: ['auth', 'user', 'files', 'watches', 'framedPage', 'kaeufermail', 'auftragsliste','vermittlungsliste','notes',
    'registration', 'auftragswizard']
})

export default new Vuex.Store({
    modules: {
        auth,
        user,
        environment,
        compliance,
        complianceDownloads,
        files,
        watches,
        framedPage,
        kaeufermail,
        auftragsliste,
        vermittlungsliste,
        notes,
        clientConfig,
        registration,
        auftragswizard
    },
    plugins: [dataState]
});
