<template>
  <a v-if="isMeinZweitmarkt" class="btn btn-default btn-bordered mr-2" v-b-toggle:nav-collapse href="https://www.zweitmarkt.de/kontakt/" target="_blank">Kontakt</a>

  <a v-else-if="isMeinHandel" class="contact-link" v-b-toggle:nav-collapse href="https://www.deutsche-zweitmarkt.de/kontakt" target="_blank">Kontakt</a>
</template>

<script>
export default {
  name: "FdbContactLink",
  computed: {
    client() {
      return this.$route?.params?.client
    },
    isMeinZweitmarkt() {
      if (this.client === "meinzweitmarkt") {
        return true;
      }
      return false;
    },
    isMeinHandel() {
      if (this.client === "meinhandel") {
        return true;
      }
      return false;
    },
  }
}
</script>

<style>

</style>