import axios from "axios";
import store from "@/store";
import Vue from "vue";

const state = {
    kaeufermaildata: [],
    lastkaeufermaildate: '',
    status: null,
    waiting: false,
};
const actions = {
    resetKaeufermailData(){
        this.dispatch('deleteKaeufermailData')
    },
    deleteKaeufermailData({commit}) {
        commit('SET_KAEUFERMAILDATA', [])
        commit('KAEUFERMAIL_ERROR', null)
    },
    setKmWaiting({commit}) {
        commit('SET_WAITING',true);
    },
    loadKaeufermail({commit},payload) {
        if ( state.kaeufermaildata.length > 0 ) {
            return;
        }
        commit('KAEUFERMAIL_ERROR', null)
        commit('SET_WAITING', true)
        const reqParams = {
            page: payload.page,
            size: payload.size,
            sort: payload.sort,
        };
        var config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded',
                'XClacksOverhead': 'GNU Terry Pratchett',
                // No API-Key yet
            },
            crossdomain: true,
            params: reqParams,
        };


        let curtClient = store.getters['getSubsite'];
        let mandantShort = "F";

        if (curtClient === "meinhandel") {
            mandantShort = "D";
        }

        return axios.get(store.getters.getAllEnvis.API_KAEUFERMAIL.replace("{mandant}",mandantShort), config)
            .then(response => {
                commit('SET_KAEUFERMAILDATA', response.data.content)
                commit('SET_WAITING', false)
            } )
            .catch(error => {
                console.log(error);
                commit('SET_WAITING', false)
                if(error.response.data.message === ''){
                    commit('KAEUFERMAIL_ERROR', 'error')
                } else {
                    commit('KAEUFERMAIL_ERROR', error.response.data.message)
                }
            })
        
    },
    loadKaeufermailDate({commit}) {
        var config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
        };

        let curtClient = store.getters['getSubsite'];
        let mandantShort = "F";

        if (curtClient === "meinhandel") {
            mandantShort = "D";
        }

        axios.get(store.getters.getAllEnvis.API_KAEUFERMAILDATE.replace("{mandant}",mandantShort), config)
            .then(response => {
                commit('SET_KAEUFERMAILDATE', response.data)
            } )
            .catch(error => {
                console.log(error);
                commit('KAEUFERMAIL_ERROR', error)
            })
    },
};
const mutations = {
    SET_KAEUFERMAILDATA: (state, responseData) => {
        Vue.set( state, "kaeufermaildata", responseData );
    },
    SET_KAEUFERMAILDATE: (state, responseData) => {
        Vue.set( state, "lastkaeufermaildate", responseData );
    },
    SET_WAITING: (state, waitingForServerResponse) =>{
        state.waiting = waitingForServerResponse
    },
    KAEUFERMAIL_ERROR: (state, errormessage) => {
        state.status = errormessage
    },
};
const getters = {
    getKaeufermailData: state => state.kaeufermaildata,
    getLastKaeufermailDate: state => state.lastkaeufermaildate,
    kaeufermailStatus: state => state.status,
    isKaeufermailWaiting: state => state.waiting,
    isKaeufermailLoaded: state => state.kaeufermaildata.length > 0,
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};


