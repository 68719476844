<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText :titletext="'Mein Zweitmarkt'" :subtext="'Komfortabel, kurzfristig und sicher handeln.'"></HeadText>
        </div>
        
        <div class="container mb-5">

          <b-tabs content-class="mt-3" nav-class="pt-3" pills>
            <b-tab title="Registrierung als Person" active v-on:click="setLegalEntityType( 'PERSON' )">

              <b-form @submit="submitForm">
                <div class="row justify-content-start pt-3">
                  <div class="col-sm-6 col-lg-5 text-left mb-4">
                    <b-form-group
                        id="input-group-1"
                        label="Anrede"
                        label-for="salutationInputField"
                        class="required"
                    >
                      <b-form-radio v-model="form.anrede" name="salutation" class="float-left mr-5" value="FRAU">Frau</b-form-radio>
                      <b-form-radio v-model="form.anrede" name="salutation" class="float-left mr-5" value="HERR">Herr</b-form-radio>
                      <b-form-radio v-model="form.anrede" name="salutation" class="float-left mr-5" value="EHELEUTE">Eheleute</b-form-radio>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-2"
                        label="Titel"
                        label-for="titleInputField"
                    >
                      <b-form-select
                          id="titleInputField"
                          size="sm"
                          class="w-100"
                          aria-describedby="titleInputField-live-feedback"
                          v-model="form.titel"
                          :options="titeloptions">
                        <template #first>
                          <b-form-select-option :value="null"></b-form-select-option>
                        </template>
                      </b-form-select>
                    </b-form-group>
                  </div>
                </div>

                <div class="row justify-content-start pt-sm-4">
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-3"
                        label="Vorname"
                        label-for="firstnameInputField"
                        class="required"
                    >
                      <b-form-input
                          id="firstnameInputField"
                          type="text"
                          aria-describedby="firstnameInputField-live-feedback"
                          v-model.trim="$v.form.vorname.$model"
                          :state="validateState('vorname')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="firstnameInputField-live-feedback">
                        <span v-if="!$v.form.vorname.maxLength">Ihr Vorname darf maximal 100 Zeichen betragen.</span>
                        <span v-else>Bitte geben Sie Ihren Vornamen an.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-4"
                        label="Nachname"
                        label-for="surnameInputField"
                        class="required"
                    >
                      <b-form-input
                          id="surnameInputField"
                          type="text"
                          aria-describedby="surnameInputField-live-feedback"
                          v-model.trim="$v.form.nachname.$model"
                          :state="validateState('nachname')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="surnameInputField-live-feedback">
                        <span v-if="!$v.form.nachname.maxLength">Ihr Nachname darf maximal 100 Zeichen betragen.</span>
                        <span v-else>Bitte geben Sie Ihren Nachnamen an.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>

                <div class="row justify-content-start pt-sm-4">
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-3"
                        label="E-Mail"
                        label-for="emailInputField"
                        class="required"
                    >
                      <b-form-input
                          id="emailInputField"
                          type="email"
                          aria-describedby="emailInputField-live-feedback"
                          v-model.trim="$v.form.email.$model"
                          :state="validateState('email')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="emailInputField-live-feedback">
                        <span v-if="!$v.form.email.maxLength">Ihre E-Mail darf maximal 255 Zeichen betragen.</span>
                        <span v-if="!$v.form.email.email">Ihre E-Mail muss eine gültige Form haben.</span>
                        <span v-else>Bitte geben Sie Ihre E-Mail an.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-4"
                        label="E-Mail (wiederholen)"
                        label-for="emailRepeatInputField"
                        class="required"
                    >
                      <b-form-input
                          id="emailRepeatInputField"
                          type="email"
                          aria-describedby="emailRepeatInputField-live-feedback"
                          v-model.trim="$v.form.emailRepeat.$model"
                          :state="validateState('emailRepeat')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="emailRepeatInputField-live-feedback">
                        <span v-if="!$v.form.emailRepeat.$model">Bitte geben Sie Ihre E-Mail wiederholt ein.</span>
                        <span v-else>E-Mail und E-Mail-Wiederholung stimmen nicht überein.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>

                <div class="row justify-content-start pt-sm-4">
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-5"
                        label-for="passwordInputField"
                        class="required"
                    >
                      <label id="passwordInputField-label" for="passwordInputField">
                        <span>Passwort <i id="passwordInputField-label-hint" class="fas fa-question-circle"></i></span>
                      </label>
                      <b-form-input
                          id="passwordInputField"
                          type="password"
                          aria-describedby="passwordInputField-live-feedback"
                          v-model.trim="$v.form.password.$model"
                          @keyup="preCheckPassword"
                          :state="validateState('password')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="passwordInputField-live-feedback">
                        <span v-if="!$v.form.password.$model">Bitte geben Sie Ihr Passwort an.</span>
                        <span v-else-if="!$v.form.password.illegalCharactersOk === true">Das Passwort enthält unerlaubte Sonderzeichen.</span>
                        <span v-else-if="!$v.form.guidelinesFulfilled === true">Das Passwort entspricht nicht den Mindestanforderungen.</span>
                        <span v-else-if="!$v.form.sufficient === true">Das Passwort ist zu schwach.</span>
                        <span v-else>Das Passwort ist ungenügend.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-6"
                        label="Passwort (wiederholen)"
                        label-for="passwordRepeatInputField"
                        class="required"
                    >
                      <b-form-input
                          id="passwordRepeatInputField"
                          type="password"
                          aria-describedby="passwordRepeatInputField-live-feedback"
                          v-model.trim="$v.form.passwordRepeat.$model"
                          :state="validateState('passwordRepeat')"
                          :disabled="passwordRepeatDisabled"
                      ></b-form-input>
                      <b-form-invalid-feedback id="passwordRepeatInputField-live-feedback">
                        <span v-if="!$v.form.passwordRepeat.$model">Bitte geben Sie Ihr Passwort wiederholt ein.</span>
                        <span v-else>Passwort und Passwortwiederholung stimmen nicht überein.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>

                <div class="row justify-content-start pt-sm-4">
                  <div class="text-left p-3">
                    <b-form-group
                        id="checkbox-group-7"
                    >
                      <b-form-checkbox 
                          class="mb-3"
                          id="agbField"
                          v-model.trim="$v.form.agbChecked.$model"
                          :state="validateState('agbChecked')"
                      >
                        Ich bestätige, dass ich die <FdbAgbLink></FdbAgbLink> heruntergeladen und gelesen habe. <span class="text-color-dark">*</span>
                      </b-form-checkbox>
                      <b-form-checkbox 
                          class="mb-3"
                          id="dataProtectionField"
                          v-model.trim="$v.form.dataProtectionChecked.$model"
                          :state="validateState('dataProtectionChecked')"
                      >
                        Ich bestätige, dass ich die <FdbDataProtectionRulesLink></FdbDataProtectionRulesLink> heruntergeladen und gelesen habe. <span class="text-color-dark">*</span>
                      </b-form-checkbox>
                      <b-form-checkbox
                          class="mb-3"
                          id="confidentialityField"
                          v-model.trim="$v.form.confidentialityChecked.$model"
                          :state="validateState('confidentialityChecked')"
                      >
                        Ich bestätige, dass ich die <a href="https://www.zweitmarkt.de/vertraulichkeit.html" target="_blank">Vertraulichkeitszustimmung</a> heruntergeladen und gelesen habe. <span class="text-color-dark">*</span>
                      </b-form-checkbox>
                      <b-form-checkbox class="mb-3" id="newsletterSubscriptionField" v-model="form.newsletterSubscription">Ich möchte die täglichen Verkaufsangebote (Käufermail) abonnieren.</b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>

                <div class="row justify-content-center pt-sm-4" v-if="this.isRegistrationWaiting">
                  <div class="text-center my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Ihre Registrierung wird gespeichert...</strong>
                  </div>
                </div>

                <div class="row pt-3 pl-3 pr-3" v-if="validationResults && validationResults.length > 0">
                  <div class="text-left w-100">
                    <b-alert show variant="danger" v-for="validationResult in validationResults" :key="validationResult">
                      {{validationResult}}
                    </b-alert>
                  </div>
                </div>

                <div class="row justify-content-center pt-sm-4" v-if="this.getRegistrationErrorStatus">
                  <div class="alert alert-warning" role="alert">
                    <p>
                      {{ this.getRegistrationErrorStatus }}
                    </p>
                    <p v-if="this.getRegistrationValidationErrors && this.getRegistrationValidationErrors.length > 0">
                      {{ this.mapErrors }}
                    </p>
                  </div>
                </div>

                <div class="row justify-content-center pt-sm-4" v-if="this.isRegistrationSuccess">
                  <div class="alert alert-info" role="alert">
                    <p>
                      Vielen Dank! Sie erhalten in Kürze eine E-Mail mit einem Link zur Bestätigung Ihrer Registrierung.
                    </p>
                  </div>
                </div>
                
                <div class="row justify-content-end pt-sm-4">
                  <div class="text-right mr-3">
                    <b-button
                        id="person-form-submit"
                        type="submit"
                        variant="default"
                        :disabled="this.isRegistrationWaiting"
                    >
                      Jetzt Registrieren
                    </b-button>
                  </div>
                </div>
                <b-tooltip v-if="hintHtml" target="passwordInputField-label-hint" triggers="hover" placement="topright">
                  <span v-html="hintHtml"></span>
                </b-tooltip>
              </b-form>

            </b-tab>
            <b-tab title="Registrierung als Firma" v-on:click="setLegalEntityType( 'FIRMA' )">

              <b-form @submit="submitForm">
                <div class="row justify-content-start pt-3">

                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-1"
                        label="Firma"
                        label-for="companyInputField"
                        class="required"
                    >
                      <b-form-input
                          id="companyInputField"
                          type="text"
                          required
                          aria-describedby="companyInputField-live-feedback"
                          v-model.trim="$v.form.firma.$model"
                          :state="validateState('firma')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="companyInputField-live-feedback">
                        <span v-if="!$v.form.firma.maxLength">Ihr Firmenname darf maximal 100 Zeichen betragen.</span>
                        <span v-else>Bitte geben Sie Ihre Firma an.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>

                <div v-if="!isMeinHandel" class="row justify-content-start pt-sm-2">
                  <div class="col-sm-12 text-left small">
                    Haben Sie Interesse an einer Anbindung als Vertriebspartner? Dann finden Sie <a href="https://www.zweitmarkt.de/kooperation/" target="_blank">hier</a> weitere Informationen.
                  </div>
                </div>

                <div class="row justify-content-start pt-sm-4">
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-2"
                        label="E-Mail"
                        label-for="emailInputField"
                        class="required"
                    >
                      <b-form-input
                          id="emailInputField"
                          type="email"
                          aria-describedby="emailInputField-live-feedback"
                          v-model.trim="$v.form.email.$model"
                          :state="validateState('email')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="emailInputField-live-feedback">
                        <span v-if="!$v.form.email.maxLength">Ihre E-Mail darf maximal 255 Zeichen betragen.</span>
                        <span v-if="!$v.form.email.email">Ihre E-Mail muss eine gültige Form haben.</span>
                        <span v-else>Bitte geben Sie Ihre E-Mail an.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-3"
                        label="E-Mail (wiederholen)"
                        label-for="emailRepeatInputField"
                        class="required"
                    >
                      <b-form-input
                          id="emailRepeatInputField"
                          type="email"
                          aria-describedby="emailRepeatInputField-live-feedback"
                          v-model.trim="$v.form.emailRepeat.$model"
                          :state="validateState('emailRepeat')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="emailRepeatInputField-live-feedback">
                        <span v-if="!$v.form.emailRepeat.$model">Bitte geben Sie Ihre E-Mail wiederholt ein.</span>
                        <span v-else>E-Mail und E-Mail-Wiederholung stimmen nicht überein.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>

                <div class="row justify-content-start pt-sm-4">
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-5"
                        label-for="passwordInputField"
                        class="required"
                    >
                      <label id="passwordInputField-label" for="passwordInputField">
                        <span>Passwort <i id="passwordInputField-label-hint" class="fas fa-question-circle"></i></span>
                      </label>
                      <b-form-input
                          id="passwordInputField"
                          type="password"
                          aria-describedby="passwordInputField-live-feedback"
                          v-model.trim="$v.form.password.$model"
                          @keyup="preCheckPassword"
                          :state="validateState('password')"
                      ></b-form-input>
                      <b-form-invalid-feedback id="passwordInputField-live-feedback">
                        <span v-if="!$v.form.password.$model">Bitte geben Sie Ihr Passwort an.</span>
                        <span v-else-if="!$v.form.password.illegalCharactersOk === true">Das Passwort enthält unerlaubte Sonderzeichen.</span>
                        <span v-else-if="!$v.form.guidelinesFulfilled === true">Das Passwort entspricht nicht den Mindestanforderungen.</span>
                        <span v-else-if="!$v.form.sufficient === true">Das Passwort ist zu schwach.</span>
                        <span v-else>Das Passwort ist ungenügend.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                  <div class="col-sm-6 col-lg-5 text-left">
                    <b-form-group
                        id="input-group-5"
                        label="Passwort (wiederholen)"
                        label-for="passwordRepeatInputField"
                        class="required"
                    >
                      <b-form-input
                          id="passwordRepeatInputField"
                          type="password"
                          aria-describedby="passwordRepeatInputField-live-feedback"
                          v-model.trim="$v.form.passwordRepeat.$model"
                          :state="validateState('passwordRepeat')"
                          :disabled="passwordRepeatDisabled"
                      ></b-form-input>
                      <b-form-invalid-feedback id="passwordRepeatInputField-live-feedback">
                        <span v-if="!$v.form.passwordRepeat.$model">Bitte geben Sie Ihr Passwort wiederholt ein.</span>
                        <span v-else>Passwort und Passwortwiederholung stimmen nicht überein.</span>
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>
                </div>

                <div class="row justify-content-start pt-sm-4">
                  <div class="text-left p-3">
                    <b-form-group
                        id="checkbox-group-7"
                    >
                      <b-form-checkbox
                          class="mb-3"
                          id="agbField"
                          v-model.trim="$v.form.agbChecked.$model"
                          :state="validateState('agbChecked')"
                      >
                        Ich bestätige, dass ich die <FdbAgbLink></FdbAgbLink> heruntergeladen und gelesen habe. <span class="text-color-dark">*</span>
                      </b-form-checkbox>
                      <b-form-checkbox
                          class="mb-3"
                          id="dataProtectionField"
                          v-model.trim="$v.form.dataProtectionChecked.$model"
                          :state="validateState('dataProtectionChecked')"
                      >
                        Ich bestätige, dass ich die <FdbDataProtectionRulesLink></FdbDataProtectionRulesLink> heruntergeladen und gelesen habe. <span class="text-color-dark">*</span>
                      </b-form-checkbox>
                      <b-form-checkbox
                          class="mb-3"
                          id="confidentialityField"
                          v-model.trim="$v.form.confidentialityChecked.$model"
                          :state="validateState('confidentialityChecked')"
                      >
                        Ich bestätige, dass ich die <a href="https://www.zweitmarkt.de/vertraulichkeit.html" target="_blank">Vertraulichkeitszustimmung</a> heruntergeladen und gelesen habe. <span class="text-color-dark">*</span>
                      </b-form-checkbox>
                      <b-form-checkbox class="mb-3" id="newsletterSubscriptionField" v-model="form.newsletterSubscription">Ich möchte die täglichen Verkaufsangebote (Käufermail) abonnieren.</b-form-checkbox>
                    </b-form-group>
                  </div>
                </div>

                <div class="row justify-content-center pt-sm-4" v-if="this.isRegistrationWaiting">
                  <div class="text-center my-2 mr-3">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Ihre Registrierung wird gespeichert...</strong>
                  </div>
                </div>

                <div class="row pt-3 pl-3 pr-3" v-if="validationResults && validationResults.length > 0">
                  <div class="text-left w-100">
                    <b-alert show variant="danger" v-for="validationResult in validationResults" :key="validationResult">
                      {{validationResult}}
                    </b-alert>
                  </div>
                </div>

                <div class="row justify-content-center pt-sm-4" v-if="this.getRegistrationErrorStatus">
                  <div class="alert alert-warning" role="alert">
                    <p>
                      {{ this.getRegistrationErrorStatus }}
                    </p>
                    <p v-if="this.getRegistrationValidationErrors && this.getRegistrationValidationErrors.length > 0">
                      {{ this.mapErrors }}
                    </p>
                  </div>
                </div>

                <div class="row justify-content-center pt-sm-4" v-if="this.isRegistrationSuccess">
                  <div class="alert alert-info mr-3" role="alert">
                    <p>
                      Vielen Dank! Sie erhalten in Kürze eine E-Mail mit einem Link zur Bestätigung Ihrer Registrierung.
                    </p>
                  </div>
                </div>

                <div class="row justify-content-end pt-sm-4">
                  <div class="text-right mr-3">
                    <b-button
                        id="company-form-submit"
                        type="submit"
                        variant="default"
                        :disabled="this.isRegistrationWaiting"
                    >
                      Jetzt Registrieren
                    </b-button>
                  </div>
                </div>
                <b-tooltip v-if="hintHtml" target="passwordInputField-label-hint" triggers="hover" placement="topright">
                  <span v-html="hintHtml"></span>
                </b-tooltip>
              </b-form>

            </b-tab>
          </b-tabs>
        </div>

      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import FdbAgbLink from "@/components/FdbAgbLink";
import FdbDataProtectionRulesLink from "@/components/FdbDataProtectionRulesLink";
import {mapGetters} from "vuex";
import testPassword from "@/mixins/testPassword";
import testForIllegalCharacters from "@/mixins/testForIllegalCharacters";

import { validationMixin } from 'vuelidate'
import {required, requiredIf, sameAs, maxLength, email} from "vuelidate/lib/validators";
import axios from "axios";

const strengthOk = (value, vm) => {
  return vm.sufficient === true
}
const agbCheckOk  = (value, vm) => {
  return vm.agbChecked === true
}
const dataProtectionCheckOk  = (value, vm) => {
  return vm.dataProtectionChecked === true
}
const confidentialityCheckOk  = (value, vm) => {
  return vm.confidentialityChecked === true
}
const minRequirementsOk = (value, vm) => {
  return vm.guidelinesFulfilled === true
}
const illegalCharactersOk = (value) => {
  return testForIllegalCharacters(value);
}

export default {
  name: "Registration",
  components: {Hero, HeadText, FdbAgbLink, FdbDataProtectionRulesLink},
  metaInfo() {
    let rel = '';
    let currentUrl = '';
    if(this.client !== 'meinzweitmarkt') {
      rel = 'canonical';
      currentUrl = window.location.href.replace(this.client, 'meinzweitmarkt');
    }
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
      ],
      link: [
        {
          rel: rel,
          href: currentUrl,
        }
      ]
    }
  },
  data() {
    return {
      error: null,
      hintHtml: null,
      form: {
        email: null,
        emailRepeat: null,
        password: null,
        passwordRepeat: null,
        legalEntityType: 'PERSON',
        firma: null,
        anrede: 'HERR',
        titel: null,
        vorname: null,
        nachname: null,
        agbChecked: null,
        dataProtectionChecked: null,
        confidentialityChecked: null,
        newsletterSubscription: false,
        // "pseudo" fields needed for validation
        sufficient: undefined,
        guidelinesFulfilled: undefined,
        noIllegalCharacters: true,
      },
      titeloptions: [
        { value: 'DR', text: 'Dr.' },
        { value: 'PROF', text: 'Prof.' },
      ],
      passwordStrengthTest: {
        variant: "danger",
        bar_fill_percant: 0,
        pwd_strength_res: "",
        sufficient: false,
      },
      validationResults: [],
    }},
  validations() {
    return {
      form: {
        vorname: {
          required: requiredIf(function () {
            return this.form.legalEntityType === 'PERSON'
          }),
          maxLength: maxLength(50),
        },
        nachname: {
          required: requiredIf(function () {
            return this.form.legalEntityType === 'PERSON'
          }),
          maxLength: maxLength(100),
        },
        firma: {
          required: requiredIf(function () {
            return this.form.legalEntityType === 'FIRMA'
          }),
          maxLength: maxLength(100),
        },
        email: {
          required,
          email,
          maxLength: maxLength(255),
        },
        emailRepeat: {
          required,
          sameAsEmail: sameAs('email')
        },
        password: {
          required,
          strengthOk,
          minRequirementsOk,
          illegalCharactersOk,
        },
        passwordRepeat: {
          required,
          sameAsPassword: sameAs('password'),
        },
        agbChecked: {
          required,
          agbCheckOk,
        },
        dataProtectionChecked: {
          required,
          dataProtectionCheckOk,
        },
        confidentialityChecked: {
          required,
          confidentialityCheckOk,
        }
      },
    }},
  computed: {
    ...mapGetters('registration', ['isRegistrationWaiting','getRegistrationErrorStatus','isRegistrationSuccess',
    'getRegistrationValidationErrors']),
    client() {
      return this.$store.getters['clientConfig/client'];
    },
    passwordRepeatDisabled() {
      var passwordGiven = this.form.password != null && 
          this.form.password !== '' && 
          this.form.sufficient && 
          this.form.guidelinesFulfilled && 
          this.form.noIllegalCharacters
      return !passwordGiven;
    },
    subsiteDisplayName() {
      return this.isMeinHandel ? 'Mein Handel' : 'Mein Zweitmarkt'
    },
    isMeinHandel() {
      return this.$store.getters["getSubsite"] === 'meinhandel';
    },
    mapErrors() {
      let errors = this.getRegistrationValidationErrors;
      let result = '';
      let firstCode = true
      for (let errorCode of errors) {
        result = result + this.lookupErrorCode( errorCode, firstCode ) + ', ';
        firstCode = false;
      }

      return result.slice(0, result.length - 2);
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    validateState(fieldName) {
      const { $dirty, $error } = this.$v.form[fieldName];
      return $dirty ? !$error : null;
    },
    getHint() {
      var subsite = null;

      if(this.$store.getters['getSubsite']) {
        subsite = this.$store.getters['getSubsite'];
      } else {
        subsite = window.location.pathname?.split('/')?.[1];
      }

      axios.get( this.$store.getters.getAllEnvis.API_PASSWORD_HINT.replace('{subsite}', subsite) )
          .then( response => {
            this.hintHtml = response.data;
          } )
          .catch(error => {
            console.log(error);
            this.error = error;
          })
    },
    preCheckPassword() {
      this.passwordStrengthTest = testPassword( this.form.password );
      this.form.sufficient = this.passwordStrengthTest.sufficient ? true : undefined;
      this.form.noIllegalCharacters = testForIllegalCharacters( this.form.password );
      var config = {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
      };
      var subsite = null;

      if(this.$store.getters['getSubsite']) {
        subsite = this.$store.getters['getSubsite'];
      } else {
        subsite = window.location.pathname?.split('/')?.[1];
      }

      const payload = JSON.stringify( {new_password: this.form.password} );
      axios.post( this.$store.getters.getAllEnvis.API_PASSWORD_CHECK.replace('{subsite}', subsite),
          payload, config )
          .then( () => {
            this.form.guidelinesFulfilled = true;
          })
          .catch(error => {
            this.form.guidelinesFulfilled = undefined;
            if ( error.response.status !== 400 ) {
              console.log(error);
              this.error = error;
            }
          })
    },
    lookupErrorCode( errorCode, firstCode ) {
      if ( 'E4000' === errorCode ) return (firstCode ? 'D' : 'd') + 'ieser Benutzername existiert bereits'
      if ( 'E4001' === errorCode ) return (firstCode ? 'E' : 'e') + 'ine Registrierung für diese E-Mail-Adresse existiert bereits'
      if ( 'E4002' === errorCode ) return (firstCode ? 'U' : 'u') + 'nbekannter Datensatztyp'
      if ( 'E4004' === errorCode ) return (firstCode ? 'U' : 'u') + 'ngültige E-Mail-Adresse'
      if ( 'E4202' === errorCode ) return (firstCode ? 'U' : 'u') + 'ngültiges Passwort'
      if ( 'E4005' === errorCode ) return (firstCode ? 'D' : 'd') + 'ie angegebene E-Mail-Adresse ist zu lang'
      if ( 'E4203' === errorCode ) return '\'Anrede\' muss angegeben werden'
      if ( 'E4021' === errorCode ) return '\'Vorname\' muss angegeben werden'
      if ( 'E4022' === errorCode ) return '\'Nachname\' muss angegeben werden'
      if ( 'E4040' === errorCode ) return '\'Firma\' muss angegeben werden'
      if ( 'E4108' === errorCode ) return '\'Firma\' ist zu lang'
      if ( 'E4109' === errorCode ) return '\'Vorname\' ist zu lang'
      if ( 'E4110' === errorCode ) return '\'Nachname\' ist zu lang'

      if ( 'E4300' === errorCode ) return 'Der Token ist ungültig'
      if ( 'E4301' === errorCode ) return 'Unbekannter Mandant'
      if ( 'E4302' === errorCode ) return 'Die Registrierung ist abgelaufen'
      if ( 'E4303' === errorCode ) return 'Die Registrierung wird bereits bearbeitet'
      if ( 'E5000' === errorCode ) return 'Die Registrierung konnte nicht gespeichert werden'
      if ( 'E5001' === errorCode ) return 'Ihr Zugang wurde angelegt. Daten zur Einstimmung zu den AGB und den Datenschutzbestimmungen konnten nicht gespeichert werden. Bitte setzen Sie sich mit uns in Verbindung.'
      return 'Unbekannter Fehlercode ' + errorCode;
    },
    setLegalEntityType( legalEntityType ) {
      this.form.legalEntityType = legalEntityType;
      if ( 'PERSON' === this.form.legalEntityType ) {
        this.form.firma = null;
        this.form.anrede = 'HERR';
      }
      else {
        this.form.anrede = null;
        this.form.titel = null;
        this.form.vorname = null;
        this.form.nachname = null;
      }
    },
    submitForm(event) {
      event.preventDefault();
      this.$v.$touch();

      if (this.$v.form.$anyError) {
        return;
      }

      this.submitRegistration();
    },
    submitRegistration() {
      const payload = {
        origin : document.location.origin,
        email : this.form.email,
        password: this.form.password,
        legalEntityType: this.form.legalEntityType,
        anrede: this.form.anrede,
        titel: this.form.titel,
        vorname: this.form.vorname,
        nachname: this.form.nachname,
        firma: this.form.firma,
        newsletterSubscription: this.form.newsletterSubscription,
      }
      this.$store.dispatch('registration/postRegistrationData',payload);
    },
  },
  mounted() {
    this.$store.dispatch('registration/resetState');
  },
  created() {
    this.getHint();
  },
  mixins: [validationMixin],
}
</script>

<style scoped>

</style>