<template>
  <a v-if="isMeinZweitmarkt" href="https://www.zweitmarkt.de/aktuelle_agb.pdf" target="_blank">AGB</a>

  <a v-else-if="isMeinHandel" href="https://www.deutsche-zweitmarkt.de/fileadmin/data/Vertragswerk/DZAG_Maklervertrag_AGB.pdf" target="_blank">AGB</a>
</template>

<script>
export default {
  name: "FdbAgbLink",
  computed: {
    client() {
      return this.$route?.params?.client
    },
    isMeinZweitmarkt() {
      if (this.client === "meinzweitmarkt") {
        return true;
      }
      return false;
    },
    isMeinHandel() {
      if (this.client === "meinhandel") {
        return true;
      }
      return false;
    },
  }
}
</script>

<style>

</style>