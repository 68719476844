import axios from "axios";
import store from '../index';

const state = {
    confidentiality: true,
};

const actions = {

    getCompliance({commit}, tid) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }

        var config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            },
        }

        return axios.get( store.getters.getAllEnvis.API_VERTRAULICHKEITSZUSTIMMUNG.replace("{guid}",tid), config)

            .then( (response) => {
                if(response.data === 1) {
                  commit('CONFIDENTIALITY', true);
                } else {
                  commit('CONFIDENTIALITY', false);
                }
            })
            .catch(error => {
                console.log( error );
            })
    },

    setConfidentialityState({commit}, state) {
        commit('CONFIDENTIALITY', state);
    }

};

const getters = {
    hasConfidentiality: state => state.confidentiality,
};

const mutations = {
    CONFIDENTIALITY: (state, value) => {
        state.confidentiality = value;
    },
};


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};