<template>
  <div style="display: inline" class="px-2 fs-7" v-if="this.showComponent">
    <span v-if="this.showRemainderVar && (this.remainingMinutes() < 5)" style="color:red">Sitzungsablauf in <span>{{ this.remaining }}</span></span>
    <span v-if="this.showRemainderVar && (this.remainingMinutes() >= 5)">Sitzungsablauf in <span>{{ this.remaining }}</span></span>
    <span v-if="this.showEndedVar">Ihre Sitzung ist abgelaufen.</span>
  </div>
</template>

<script>
import VueJwtDecode from "vue-jwt-decode";

export default {
  name: "SessionRemainder",
  data: () => ({
    exp: 0,
    showComponent: false,
    showRemainderVar: false,
    showEndedVar: false,
    remaining: '',
    interval: 0,
  }),
  methods: {
    token() {
      return this.$store.getters["auth/getToken"]
    },
    calcData() {
      this.showComponent = this.showAtAll();
      this.exp = this.getExp(); 
      this.showRemainderVar = this.showRemainder();
      this.showEndedVar = this.showEnded();
      this.remaining = this.remainingTime();
    },
    getExp() {
      var token = this.token();
      if ( !token ) {
        return 0;
      }
      var decodedToken = VueJwtDecode.decode(token);
      return decodedToken.exp * 1000; // exp is a timestamp in seconds, not millis  
    },
    showAtAll() {
      var stage = this.$store.getters["getStage"];
      if ( stage === 'prod' ) {
        return false;
      }
      var token = this.token();
      if ( !token ) {
        return false;
      }
      var decodedToken = VueJwtDecode.decode(token);
      var loginName = decodedToken.sub;
      return loginName.endsWith('@efonds.com');
    },
    showRemainder() {
      var now = Date.now();
      return this.exp > now;
    },
    showEnded() {
      var now = Date.now();
      return this.exp <= now;
    },
    remainingMinutes() {
      var remainingMillis = this.exp - Date.now();
      if ( remainingMillis <= 0 ) {
        return 0;
      } 
      return Math.floor(( remainingMillis / (1000*60) ) % 60 );
    },
    remainingTime() {
      var remainingMillis = this.exp - Date.now();
      if ( remainingMillis <= 0 ) {
        return '0s';
      }
      
      var minutes = this.remainingMinutes();
      var seconds = Math.floor(( remainingMillis / (1000) ) % 60 );
      return minutes + ':' + ((seconds < 10) ? '0' : '') + seconds;
    }
  },
  created() {
    this.interval = setInterval(this.calcData, 1000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
}
</script>

<style>

</style>