import axios from 'axios';
import VueJwtDecode from 'vue-jwt-decode';
import store from '../index';
import router from "../../router";

const state = {
    token: '',
    status: '',
};
const getters = {
    hasToken: (state) => {
        let token = state.token;
        return !(token === null || token === undefined || token === '' || token === "");
    },
    getToken: (state) => {
        return state.token
    },
    authStatus: (state) => {
        return state.status;
    },
};

const actions = {
    login({commit}, formData){
        const params = new URLSearchParams();
        let subsite = store.getters['getSubsite']
        params.append('username',formData.username.trim());
        params.append('password',formData.password);
        let apiUrl = store.getters.getAllEnvis.API_LOGIN + '/' + subsite
        return axios.post(apiUrl, params)
            .then(res => {
                let token = res.data.access_token
                commit('AUTH_SUCCESS')
                commit('SET_TOKEN', {token})
                const decoded = VueJwtDecode.decode(token);
                if ( decoded.otp ) {
                    this.dispatch('user/setChangePasswordEnforced')    
                }
                this.dispatch('auth/refreshCookie')
            })
            .catch(error => {
                if ( error.response.data.message === 'password-expired' || error.response.data.message === 'tmp-password-expired' ) {
                    commit('AUTH_EXPIRED')
                } else {
                    commit('AUTH_ERROR')    
                }
                console.log(error)
            })
    },
    acceptRemoteToken({commit}, formData) {
        let token = formData.token;
        commit('AUTH_SUCCESS')
        commit('SET_TOKEN', {token})
        this.dispatch('auth/refreshCookie').then(response => {
            let debugRun = undefined;
            if ( debugRun ) console.log( response )
            if ( formData.targetPage ) {
                const payload = {
                    name: formData.targetPage,
                } ;
                this.dispatch('auth/backToOrigin', payload )
            }
            else {
                this.dispatch('auth/backToMain')
            }
        }, error => {
            console.log( error )
        })
        
    },
    checkRefreshTime(){
        let token = store.getters["auth/getToken"];

        if(token !== undefined && token !== '' && token !== null){
            const decodedTok = VueJwtDecode.decode(token)
            let jetzt = new Date()
            let refreshTime = (decodedTok.exp*1000 - jetzt.getTime())/1000

            if(refreshTime <= 900 && refreshTime >= 0){
                this.dispatch('auth/refreshCookie')
            }
            if( refreshTime <= 0 ){
                this.dispatch('auth/logout')
            }
        }

    },
    refreshCookie(){
        let token = store.getters["auth/getToken"];
        return this.dispatch('auth/refreshToken', {token})
    },
    refreshToken({commit}, {token}){
        if ( !token ) { return }
        let apiUrl = store.getters["getAllEnvis"].API_LOGIN
        const decoded = VueJwtDecode.decode(token);
        if ( decoded.exp * 1000 <= Date.now() ) {
            this.dispatch('auth/doLogout');
            return;
        }

        const config = {
            'access_token' : `${token}`,
            'token_type': 'Bearer',
        }
        return axios.post( apiUrl, config)
            .then(response => {
                const newToken = response.data.access_token
                commit('AUTH_SUCCESS')
                commit('SET_NEW_TOKEN', {newToken});
                this.dispatch('user/decodeJwtAndSaveData');
            } )
            .catch(error => {
                    console.log(error)
                }
            )
    },
    logout() {
        return this.dispatch('auth/doLogout');
    },
    doLogout( {commit} ) {
        localStorage.removeItem('auth')
        localStorage.removeItem('user')
        commit('AUTH_LOGOUT')
        store.dispatch('user/deleteUsername')
        store.dispatch('watches/resetWatches')
        store.dispatch('auth/backToMain')
    },
    setLogoutState( {commit} ) {
        commit('AUTH_LOGOUT')
    },
    backToMain() {
        router.push({name: 'fondslist', params: {client: store.getters['getSubsite']}});
    },
    // eslint-disable-next-line no-unused-vars
    backToOrigin( {commit}, payload ) {
        if ( payload.name.endsWith( "registerconfirm" ) ||
            payload.name.endsWith( "ConfirmRegistration" ) ||
            payload.name.endsWith( "newsletterconfirm" ) ||
            payload.name.endsWith( "ConfirmNewsletterRegistration" ) ||
            payload.name.endsWith( "viewlimit" )) {
            store.dispatch('auth/backToMain')
            return;
        }
        const enforcePasswordChange = store.getters['user/isChangePasswordEnforced'];
        if (enforcePasswordChange) {
            router.push({name: 'ChangePassword', params: {client: this.$store.getters['getSubsite']}});
        } else if (store.getters['auth/authStatus'] === 'error' || store.getters['auth/authStatus'] === 'password-expired') {
            router.push({name: 'login', params: {client: store.getters['getSubsite']}});
        } else if ( payload.name.startsWith( 'fondsdetails' ) ) {
            const trancheId = payload.name.split('/')[1];
            router.push({name: 'fondsdetails', params: {client: store.getters['getSubsite'], id: trancheId}});
        } else {
            router.push({name: payload.name, params: {client: store.getters['getSubsite']}});
        }
    },
};

const mutations = {
    AUTH_SUCCESS: (state) => {
        state.status = 'success'
    },
    AUTH_ERROR: (state) => {
        state.status = 'error'
        state.token = ''
    },
    AUTH_EXPIRED: (state) => {
        state.status = 'password-expired'
        state.token = ''
    },
    AUTH_LOGOUT: (state) => {
        state.status = 'logout'
        state.token = ''
    },
    AUTH_LOGGED_IN: (state) => {
        state.status = 'loggedin'
    },
    SET_TOKEN: (state, {token}) => {
        state.token = token;
    },
    SET_NEW_TOKEN: (state, {newToken}) => {
        state.token = newToken;
    }
};
export default {
    router,
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
