import VueJwtDecode from 'vue-jwt-decode'
import axios from "axios";
import store from "@/store";

const state = {
    userNameFromToken: '',
    loginName: '',
    name: '',
    guid: '',
    loginId: '',
    userProfileData: {},
    kundendaten: [],
    infoservices: {},
    status: '',
    waiting: false,
    saving: false,
    success: null,
    changePasswordEnforced: false,
};

const getters = {
    userNameFromToken: state => state.userNameFromToken,
    loginNameFromToken: state => state.loginName,
    guidFromToken: state => state.guid,
    loginIdFromToken: state => state.loginId,
    userProfileData: state => state.userProfileData,
    kundendaten: state => state.kundendaten,
    infoserviceData: state => state.infoservices,
    status: state => state.status,
    isWaiting: state => state.waiting,
    isSaving: state => state.saving,
    isSuccess: state => state.success,
    isChangePasswordEnforced: state => state.changePasswordEnforced,
};

const actions = {
    deleteUsername({ commit }) {
        commit('deleteUsernameFromToken');    
    },
    decodeJwtAndSaveData({ commit }){
        const encoded = store.getters['auth/getToken'];
        const decoded = VueJwtDecode.decode(encoded)
        commit('saveUser', {fullname: decoded.fullname, loginName: decoded.sub, guid: decoded.guid, loginId: decoded.lid, otp: decoded.otp})
    },
    setChangePasswordEnforced({commit}) {
        commit('SET_CHANGE_PASSWORD_ENFORCED',true);
    },
    resetChangePasswordEnforced({commit}) {
        commit('SET_CHANGE_PASSWORD_ENFORCED',false);    
    },
    loadUserProfile(){
        let token = store.getters['auth/getToken']
        if(token !== '' && token !== undefined) {
            this.dispatch('user/loadUserProfileWithToken')
        }
    },
    loadUserProfileWithToken({commit}) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('userProfileError', null)
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            crossdomain: true,
        };
        const uri = store.getters.getAllEnvis.API_USER_PROFILE.replace("{guid}",store.getters['user/guidFromToken']);
        console.log( uri );
        axios.get(uri, config)
            .then(response => {
                commit('saveUserProfileData', response.data)
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
            } )
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                if(error.response.data.message === ''){
                    commit('userProfileError', 'error')
                } else {
                    commit('userProfileError', error.response.data.message)
                }
            })
    },
    loadKundendaten(){
        let token = store.getters['auth/getToken']
        if(token !== '' && token !== undefined) {
            this.dispatch('user/loadKundendatenWithToken')
        }
    },
    loadKundendatenWithToken({commit}) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        commit('userProfileError', null)
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            crossdomain: true,
        };
        const uri = store.getters.getAllEnvis.API_KUNDENDATEN.replace("{guid}",store.getters['user/guidFromToken']);
        console.log( uri );
        axios.get(uri, config)
            .then(response => {
                commit('saveKundendaten', response.data)
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
            } )
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                if(error.response.data.message === ''){
                    commit('userProfileError', 'error')
                } else {
                    commit('userProfileError', error.response.data.message)
                }
            })
    },
    saveAdressData( {commit}, payload ) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        const reqParams = {
            strasse:            payload.strasse,
            hausnummer:         payload.hausnummer,
            zusatz:             payload.zusatz,
            plz:                payload.plz,
            ort:                payload.ort,
            land:               payload.land,
            zweck:              payload.zweck,
            standard:           payload.zweck === 'Alternative Postadresse' ? 'false' : 'true',
        };
        var config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            crossdomain: true,
        };

        commit('saveAddressError', null)
        let url = store.getters.getAllEnvis.API_ADRESSE.replace("{guid}",store.getters['user/guidFromToken']);
        return axios.post(url,reqParams,config)
            .then(response => {
                commit('saveUserProfileData', response.data)
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
                return response
            })
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                if(error.response.data.message === ''){
                    commit('saveAddressError', 'error')
                } else {
                    commit('saveAddressError', error.response.data.message)
                }
            })
    },
    deleteAddressData( {commit}, payload ) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        const reqParams = {
            strasse:            payload.strasse,
            hausnummer:         payload.hausnummer,
            zusatz:             payload.zusatz,
            plz:                payload.plz,
            ort:                payload.ort,
            land:               payload.land,
            zweck:              payload.zweck,
            standard:           true,
        };
        var config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            data: reqParams,
            crossdomain: true,
        };

        commit('saveAddressError', null)
        let url = store.getters.getAllEnvis.API_ADRESSE.replace("{guid}",store.getters['user/guidFromToken']);
        return axios.delete(url,config)
            .then(response => {
                commit('saveUserProfileData', response.data)
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
            })
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                if(error.response.data.message === ''){
                    commit('saveAddressError', 'error')
                } else {
                    commit('saveAddressError', error.response.data.message)
                }
            })
    },
    saveTelefonData( {commit}, payload ) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        const reqParams = {
            typ:            payload.typ,
            zweck:          payload.zweck,
            standard:       payload.standard,
            laenderkennung: payload.laenderkennung,
            vorwahl:        payload.vorwahl,
            rufnummer:      payload.rufnummer,
        };
        var config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            crossdomain: true,
        };

        commit('saveTelefoneError', null)
        let url = store.getters.getAllEnvis.API_TELEFON.replace("{guid}",store.getters['user/guidFromToken']);
        return axios.post(url,reqParams,config)
            .then(response => {
                commit('saveUserProfileData', response.data)
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
                return response
            })
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                if(error.response.data.message === ''){
                    commit('saveTelefoneError', 'error')
                } else {
                    commit('saveTelefoneError', error.response.data.message)
                }
            })
    },
    saveFaxData( {commit}, payload ) {
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        commit('SET_WAITING', true)
        commit('SET_SUCCESS', false)
        const reqParams = {
            typ:            payload.typ,
            zweck:          payload.zweck,
            standard:       payload.standard,
            laenderkennung: payload.laenderkennung,
            vorwahl:        payload.vorwahl,
            rufnummer:      payload.rufnummer,
        };
        var config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            },
            crossdomain: true,
        };

        commit('saveTelefoneError', null)
        let url = store.getters.getAllEnvis.API_FAX.replace("{guid}",store.getters['user/guidFromToken']);
        return axios.post(url,reqParams,config)
            .then(response => {
                commit('saveUserProfileData', response.data)
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', true)
                return response
            })
            .catch(error => {
                console.log( error );
                commit('SET_WAITING', false)
                commit('SET_SUCCESS', false)
                if(error.response.data.message === ''){
                    commit('saveTelefoneError', 'error')
                } else {
                    commit('saveTelefoneError', error.response.data.message)
                }
            })
    },
    loadInfoservices({commit} ) {
        return new Promise((resolve, reject) => {
            let token = store.getters['auth/getToken']
            if(token === '' || token === undefined){
                throw new Error('Missing Token!')
            }
            commit('SET_SAVING', false)
            commit('SET_WAITING', true)
            commit('SET_SUCCESS', false)
            commit('userProfileError', null)
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                crossdomain: true,
            };
            const uri = store.getters.getAllEnvis.API_INFOSERVICES.replace("{loginId}",store.getters['user/loginIdFromToken']);
            console.log( uri );
            axios.get( uri, config )
                .then(response => {
                    commit('saveInfoservices', response.data)
                    commit('SET_WAITING', false)
                    commit('SET_SUCCESS', true)
                    resolve( response )
                }, error => {
                    reject(error); 
                } )
                .catch(error => {
                    console.log( error );
                    commit('SET_WAITING', false)
                    commit('SET_SUCCESS', false)
                    if(error.response.data.message === ''){
                        commit('userProfileError', 'error')
                    } else {
                        commit('userProfileError', error.response.data.message)
                    }
                })
        })
    },
    storeInfoservices({commit},formData) {
        return new Promise((resolve, reject) => {
            let token = store.getters['auth/getToken']
            if (token === '' || token === undefined) {
                throw new Error('Missing Token!')
            }
            commit('SET_SAVING', true)
            commit('SET_SUCCESS', false)
            commit('userProfileError', null)
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                crossdomain: true,
            };
            var params = {
                defaultEmailAddress: formData.defaultEmailAddress,
                newsletterPermission: formData.newsletterPermission,
                infoservices: map(formData),
            }
            const uri = store.getters.getAllEnvis.API_INFOSERVICES.replace("{loginId}", store.getters['user/loginIdFromToken']);
            console.log(uri);
            axios.post(uri, params, config)
                .then(response => {
                    commit('saveInfoservices', response.data)
                    commit('SET_SAVING', false)
                    commit('SET_SUCCESS', true)
                    resolve( response )
                }, error => {
                    reject(error);
                })
                .catch(error => {
                    console.log(error);
                    commit('SET_SAVING', false)
                    commit('SET_SUCCESS', false)
                    if (error.response.data.message === '') {
                        commit('userProfileError', 'error')
                    } else {
                        commit('userProfileError', error.response.data.message)
                    }
                })
        })
    },
};


function decode_utf8(s) {
    return decodeURIComponent(escape(s));
}

function map( formData ) {
    var result = [];
    for ( let index in formData.infoservices ) {
        let infoservice = formData.infoservices[index];
        var data = {
            infoserviceId: infoservice.infoserviceId, 
            subscribed: infoservice.subscribed,
            emailAddress: infoservice.emailAddress ? infoservice.emailAddress : null,
        }
        result.push(data);
    }
    return result;
}

const mutations = {
    saveUser(state, data) {
        state.userNameFromToken = decode_utf8(data.fullname);
        state.loginName = data.loginName;
        state.guid = data.guid;
        state.loginId = data.loginId;
        // If otp === true, then the token is only valid for 20 minutes and won't 
        // be re-issued until a new password has been set. The token can be refreshed, though.
        state.changePasswordEnforced = data.otp === true;
    },
    saveUserProfileData( state, data ) {
        state.userProfileData = {
            name: '',
            typ: '',
            ...data,
            anschrift: {
                strasse: '',
                hausnummer: '',
                zusatz: '',
                plz: '',
                land: '',
                standard: true,
                zweck: data.typ === 'Person' ? 'Postadresse privat' : 'Lieferadresse (Straße)',
                ...data.anschrift,
            },
            alternativeanschrift: {
                strasse: '',
                hausnummer: '',
                zusatz: '',
                plz: '',
                land: '',
                standard: false,
                zweck: 'Alternative Postadresse',
                ...data.alternativeanschrift,
            },
            telefon_typ: '',
            telefon_zweck: '',
            telefon_standard: true,
            telefon_laenderkennung: '',
            telefon_vorwahl: '',
            telefon_rufnummer: '',
            mobil_typ: '',
            mobil_zweck: '',
            mobil_standard: true,
            mobil_laenderkennung: '',
            mobil_vorwahl: '',
            mobil_rufnummer: '',
            fax_typ: '',
            fax_zweck: '',
            fax_standard: true,
            fax_laenderkennung: '',
            fax_vorwahl: '',
            fax_rufnummer: '',
        }
        if ( data.telefon ) {
            state.userProfileData.telefon_typ = data.telefon.typ;
            state.userProfileData.telefon_zweck = data.telefon.zweck;
            state.userProfileData.telefon_standard = data.telefon.standard;
            state.userProfileData.telefon_laenderkennung = data.telefon.laenderkennung;
            state.userProfileData.telefon_vorwahl = data.telefon.vorwahl;
            state.userProfileData.telefon_rufnummer = data.telefon.rufnummer;
        }
        if ( data.mobil ) {
            state.userProfileData.mobil_typ = data.mobil.typ;
            state.userProfileData.mobil_zweck = data.mobil.zweck;
            state.userProfileData.mobil_standard = data.mobil.standard;
            state.userProfileData.mobil_laenderkennung = data.mobil.laenderkennung;
            state.userProfileData.mobil_vorwahl = data.mobil.vorwahl;
            state.userProfileData.mobil_rufnummer = data.mobil.rufnummer;
        }
        if ( data.fax ) {
            state.userProfileData.fax_typ = data.fax.typ;
            state.userProfileData.fax_zweck = data.fax.zweck;
            state.userProfileData.fax_standard = data.fax.standard;
            state.userProfileData.fax_laenderkennung = data.fax.laenderkennung;
            state.userProfileData.fax_vorwahl = data.fax.vorwahl;
            state.userProfileData.fax_rufnummer = data.fax.rufnummer;
        }
    },
    saveKundendaten( state, data ) {
        state.kundendaten = data;
    },
    saveInfoservices( state, data ) {
        state.infoservices = data;
    },
    deleteUsernameFromToken(state){
        state.userNameFromToken = '';
        state.loginName = '';
        state.guid = '';
        state.loginId = '';
        state.userProfileData = [];
        state.kundendaten = [];
        state.infoservices = {};
        state.changePasswordEnforced = false;
    },
    userProfileError: (state, errormessage) => {
        state.status = errormessage
    },
    saveAddressError: (state, errormessage) => {
        state.status = errormessage
    },
    saveTelefoneError: (state, errormessage) => {
        state.status = errormessage
    },
    SET_WAITING: (state, waitingForServerResponse) =>{
        state.waiting = waitingForServerResponse
    },
    SET_SAVING: (state, waitingForServerResponse) =>{
        state.saving = waitingForServerResponse
    },
    SET_SUCCESS: (state, success) => {
        state.success = success
    },
    SET_CHANGE_PASSWORD_ENFORCED: (state, changePasswordEnforced) => {
        state.changePasswordEnforced = changePasswordEnforced;    
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};