import axios from "axios";
import store from "@/store";

const state = {
    filestatus: null
};
const actions = {
    resetStatus({commit}) {
        commit('FILE_RETRIEVAL_ERROR', null);        
    },
    downloadFileWithToken({commit},payload) {
        commit('FILE_RETRIEVAL_ERROR', null);
        let token = store.getters['auth/getToken']
        if(token === '' || token === undefined){
            throw new Error('Missing Token!')
        }
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                'Accept': 'application/pdf',
                'Content-Type': 'application/x-www-form-urlencoded',
                'XClacksOverhead': 'GNU Terry Pratchett',
                'X-API-KEY': store.getters.getAllEnvis.API_KEY,
            },
            responseType : 'blob',
        };
        axios.get(
            payload.href,
            config
        )
            .then(response => {
                
                let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                let fileLink = document.createElement('a');

                fileLink.href = fileURL;
                fileLink.setAttribute('download', payload.filename);
                document.body.appendChild(fileLink);

                fileLink.click();
                URL.revokeObjectURL( fileURL );
            }, response => {
                console.log( "Failure in request" )
                console.log( response )
            } )
            .catch(error => {
                console.log(error)
                commit('FILE_RETRIEVAL_ERROR', 'Fehler beim Datei-Download!')
            })
    },
};
const mutations = {
    FILE_RETRIEVAL_ERROR: (state, errormessage) => {
        state.filestatus = errormessage
    }
};
const getters = {
    fileRetrievalStatus: state => state.filestatus,
    hasFileError: state => {
        var status = getters.fileRetrievalStatus( state );
        return !(status === null || status === undefined || status === '' || status === "");
    },
}

export default {
    state,
    getters,
    actions,
    mutations
};