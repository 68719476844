export const selectDeep = (obj, path = '') => {
  //return the whole object if !path
  if (!path) return obj

  const map = path.split('.')
  const key = map.shift()

  obj = typeof obj === 'object' && obj !== null ? obj[key] : obj

  return map.length < 1 || obj === undefined ? obj : selectDeep(obj, map.join('.'))
}

export default selectDeep
