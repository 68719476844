<template>
  <div>
    <section class="top-margin">
        <Hero>
          <div class="container">
            <HeadText></HeadText>
          </div> 
          <div class="container bg-light">
            <StylesDemo></StylesDemo>
          </div>
        </Hero>
    </section>
  </div>
</template>

<script>
import HeadText from "@/components/HeadText";
import Hero from "@/components/HeroPicture";
import StylesDemo from "@/components/StylesDemo";


export default {
  name: 'Home',
  components: {StylesDemo, Hero, HeadText},
  metaInfo: {
    title: 'Homie!'
  }
}
</script>
