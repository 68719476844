<template>
  <div class="hero w-100">
    <div class="text-center">
      <slot/>
    </div>
  </div>

</template>

<script>
export default {
  name: "Hero",
  props: {
    imageUrl: {
      type: String,
      default: '/images/hero/fondsboerse_backgroundimage_tmp3.png',
    }
  }
}

</script>

<style lang="scss" scoped>
</style>
