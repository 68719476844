import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router/index.js'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueCookie from 'vue-cookie'
import { BootstrapVue, BadgePlugin } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import $ from 'jquery'
import VueHead from 'vue-head'
import VueHtml2pdf from 'vue-html2pdf'
import './directives/scroll'
import VueMeta from 'vue-meta'
import VueCookieAcceptDecline from 'vue-cookie-accept-decline'
import VueEvents from 'vue-events'


//STYLE
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@fortawesome/fontawesome-pro/css/all.css'

Vue.use(VueAxios, axios)
Vue.use(Vuex)
Vue.use(VueCookie)
Vue.use(VueHead)
Vue.use(BootstrapVue, $, VueHtml2pdf)
Vue.use(VueApexCharts, store)
Vue.use(BadgePlugin)
Vue.use(VueMeta)
Vue.use(VueEvents)
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false
Vue.component('vue-cookie-accept-decline', VueCookieAcceptDecline)

const vue = new Vue({
  router, store,
  render: h => h(App)
}).$mount('#app')

if (process.env.NODE_ENV === 'development') {
    window.$vue = vue
    window.$store = vue.$store
}
