import Vue from 'vue'
import axios from 'axios'
import selectDeep from '@/utils/selectDeep'

const isEnvDevelopment = () => process.env.NODE_ENV === 'development'

export const state = () => ({
  client: undefined,
  loaded: false,
  isValid: false,
  config: undefined,
  _promise: undefined,
})

export const getters = {
  client: state => state.client,
  loaded: state => state.loaded,
  isValid: state => state.client && state.loaded,
  config: state => path => selectDeep(state.config, path),
}

export const mutations = {
  client: (state, client) => state.client = client,
  loaded: (state, loaded) => state.loaded = loaded,
  config: (state, data) => Vue.set(state, 'config', data),
}

export const actions = {
  async getConfig({ getters, commit, dispatch }, client) {
    if (getters.isValid) return getters.config;

      let config = null;

      if (isEnvDevelopment()) {
         await import(`@/assets/clients/${client}/config`).then(response => {
          config = response.default;
        });
      } else {
        await axios.get('/api/client/' + client + '/config').then(response => {
          config = response.data;
        });
      }

    commit('client', client)

    if(config === null) {
        commit('client', undefined)
        commit('loaded', false)

        return undefined;
      }

      commit('config', config);
      commit('loaded', true);

      dispatch('setSubsite', { subsite: client }, { root: true });

      return config;
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
