<template>
  <div id="app" @click="checkCookie">
    <template v-if="isValidClient">
      <NavigationTop />
      <transition name="fade" appear mode="out-in">
        <router-view />
      </transition>
      <ExternalSourceFooter />
    </template>
    <template v-else>
      <router-view />
    </template>
  </div>
</template>

<script>
  import NavigationTop from '@/components/TheNavigationTop';
  import ExternalSourceFooter from '@/components/ExternalSourceFooter';

  import LocalConfig from '/public/config/config.json';
  import VueJwtDecode from "vue-jwt-decode";

  export default {
    components: {
      NavigationTop,
      ExternalSourceFooter,
    },
    head() {
      return {
        link: this.metaLink,
        title: this.metaTitle
      }
    },

    metaInfo() {

      let robotsFollow = "";
      let googlebotFollow = "";
      let bingbot = "";

      if (this.isValidClient) {
        robotsFollow = 'index, follow';
        googlebotFollow = 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1';
        bingbot = 'index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      } else {
        robotsFollow = 'noindex, nofollow';
        googlebotFollow = 'noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1';
        bingbot = 'noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1'
      }

      return {
        htmlAttrs: {
          lang: 'de-DE'
        },
        meta: [
          /* Note: 'description' must be set in each page. Default see Fondslist.vue */
          { charset: 'utf-8' },
          { name: 'author', content: 'eFonds AG' },
          { name: 'Content-Type', content: 'text/html; charset=UTF-8' },
          { name: 'keywords', content: 'Fonds, Geschlossene Fonds, Immobilienfonds, Leasingfonds, Medienfonds, Schiffsbeteiligungen, Schiffsfonds, Venture Capital, Venture Capital Fonds, Umweltfonds, Windkraftanlagen, Windkraftfonds, Zweitmarkt, Vermögensberatung, Vermögensanlage, Kommanditbeteiligungen, Neuemissionen, Kapitalanlagen, Kapitalanlage, Beteiligungsmodelle, Anteilshandel, Hollandfonds, Auslandfonds, US-Fonds' },

          { name: 'robots', content: robotsFollow, vmid: 'robots'},
          { name: 'googlebot', content: googlebotFollow, vmid: 'googlebot' },
          { name: 'bingbot', content: bingbot, vmid: 'bingbot' },
        ]
      }
    },
    data: () => ({
      sessionTimeoutCheckInterval: 0,
    }),
    computed: {
      client() {
        return this.$store.getters['clientConfig/client']
      },
      isValidClient() {
        return this.$store.getters['clientConfig/isValid'] || 'meinzweitmarkt' === window.location.pathname?.split('/')?.[1]
      },
      clientLoaded() {
        return this.$store.getters['clientConfig/loaded']
      }
    },
    methods: {
      checkSessionTimeout() {
        const hasToken = this.$store.getters['auth/hasToken'];
        if ( !hasToken ) {
          return;
        }
        const decoded = VueJwtDecode.decode(this.$store.getters['auth/getToken']);
        const tokenIsExpired = decoded.exp * 1000 <= Date.now()
        if ( tokenIsExpired ) {
          console.log( 'Session expired, logging out')
          this.$store.dispatch('auth/doLogout');
        }
      },
      getClientConfig(path) {
        return this.$store.getters['clientConfig/config'](path)
      },
      metaTitle() {
        if (!this.clientLoaded) return {
          name: 'Fondsbörse Deutschland | Geschlossene Fonds kaufen und verkaufen | Fondsbörse Deutschland',
          separator: '',
          complement: '',
        }

        if (!this.isValidClient) return {
          name: 'Error',
          separator: '|',
          complement: 'client not exist!',
        }

        return {
          inner: this.getClientConfig('name') || this.client,
          separator: '|',
          complement: this.getClientConfig('title'),
        }
      },
      metaLink() {
        return [
          {
            rel: 'stylesheet',
            href: `/clients/${this.client || window.location.pathname?.split('/')?.[1]}/css/main.${LocalConfig.hash}.css`,
          },
          {
            rel: 'icon',
            id: 'favicon',
            href: `/favicon/${this.getClientConfig('favicon')}`,
          }
        ]
      },
      checkCookie: function(){
        this.$store.dispatch("auth/refreshCookie")  
      }
    },
    watch: {
      isValid(value) {
        if (value) this.$emit('updateHead')
      },
      clientLoaded(value) {
        if (value) this.$emit('updateHead')
      },
    },
    mounted() {
      this.$nextTick(function () {
        this.checkCookie()
      })
    },
    created() {
      this.$store.dispatch('loadEnv')
      this.sessionTimeoutCheckInterval = setInterval(this.checkSessionTimeout, 1000);
    },
    beforeDestroy() {
      window.removeEventListener('beforeunload', this.checkCookie)
      clearInterval(this.sessionTimeoutCheckInterval)
    }
  }
</script>

<style lang="scss">
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
